.fa, .fas, .far, .fal, .fad, .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2em;
  line-height: inherit;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fas.fa-pull-left, .far.fa-pull-left, .fal.fa-pull-left, .fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fas.fa-pull-right, .far.fa-pull-right, .fal.fa-pull-right, .fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: fa-spin 2s linear infinite;
}

.fa-pulse {
  animation: fa-spin 1s steps(8, end) infinite;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-alien-monster:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-analytics:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-crate:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-alt-down:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-arrow-alt-from-left:before {
  content: "";
}

.fa-arrow-alt-from-right:before {
  content: "";
}

.fa-arrow-alt-from-top:before {
  content: "";
}

.fa-arrow-alt-left:before {
  content: "";
}

.fa-arrow-alt-right:before {
  content: "";
}

.fa-arrow-alt-square-down:before {
  content: "";
}

.fa-arrow-alt-square-left:before {
  content: "";
}

.fa-arrow-alt-square-right:before {
  content: "";
}

.fa-arrow-alt-square-up:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-arrow-alt-to-left:before {
  content: "";
}

.fa-arrow-alt-to-right:before {
  content: "";
}

.fa-arrow-alt-to-top:before {
  content: "";
}

.fa-arrow-alt-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-from-bottom:before {
  content: "";
}

.fa-arrow-from-left:before {
  content: "";
}

.fa-arrow-from-right:before {
  content: "";
}

.fa-arrow-from-top:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-square-down:before {
  content: "";
}

.fa-arrow-square-left:before {
  content: "";
}

.fa-arrow-square-right:before {
  content: "";
}

.fa-arrow-square-up:before {
  content: "";
}

.fa-arrow-to-bottom:before {
  content: "";
}

.fa-arrow-to-left:before {
  content: "";
}

.fa-arrow-to-right:before {
  content: "";
}

.fa-arrow-to-top:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-atom-alt:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-barcode-alt:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-bed-alt:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-betamax:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-biking-mountain:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-alt:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-book-spells:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-border-style-alt:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-alt:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-box-fragile:before {
  content: "";
}

.fa-box-full:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-box-up:before {
  content: "";
}

.fa-box-usd:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-boxes-alt:before {
  content: "";
}

.fa-boxing-glove:before {
  content: "";
}

.fa-brackets:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calculator-alt:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-edit:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camcorder:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-alt:before {
  content: "";
}

.fa-camera-home:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-car-mechanic:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caravan-alt:before {
  content: "";
}

.fa-caret-circle-down:before {
  content: "";
}

.fa-caret-circle-left:before {
  content: "";
}

.fa-caret-circle-right:before {
  content: "";
}

.fa-caret-circle-up:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cctv:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-chart-pie-alt:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-cheeseburger:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-bishop-alt:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-chess-clock-alt:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-king-alt:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-knight-alt:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-pawn-alt:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-queen-alt:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chess-rook-alt:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-double-down:before {
  content: "";
}

.fa-chevron-double-left:before {
  content: "";
}

.fa-chevron-double-right:before {
  content: "";
}

.fa-chevron-double-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-square-down:before {
  content: "";
}

.fa-chevron-square-left:before {
  content: "";
}

.fa-chevron-square-right:before {
  content: "";
}

.fa-chevron-square-up:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-coffee-togo:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-alt-check:before {
  content: "";
}

.fa-comment-alt-dollar:before {
  content: "";
}

.fa-comment-alt-dots:before {
  content: "";
}

.fa-comment-alt-edit:before {
  content: "";
}

.fa-comment-alt-exclamation:before {
  content: "";
}

.fa-comment-alt-lines:before {
  content: "";
}

.fa-comment-alt-medical:before {
  content: "";
}

.fa-comment-alt-minus:before {
  content: "";
}

.fa-comment-alt-music:before {
  content: "";
}

.fa-comment-alt-plus:before {
  content: "";
}

.fa-comment-alt-slash:before {
  content: "";
}

.fa-comment-alt-smile:before {
  content: "";
}

.fa-comment-alt-times:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-edit:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-comment-times:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-comments-alt-dollar:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-construction:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-conveyor-belt-alt:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-cowbell-more:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-cricket:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-curling:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-debug:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-desktop-alt:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dewpoint:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digging:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-diploma:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-drone-alt:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-dryer-alt:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-eclipse-alt:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-h-alt:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ellipsis-v-alt:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-engine-warning:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-square:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-farm:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-field-hockey:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-certificate:before {
  content: "";
}

.fa-file-chart-line:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-edit:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-search:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-file-times:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-film-alt:before {
  content: "";
}

.fa-film-canister:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-alt:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flame:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flask-poison:before {
  content: "";
}

.fa-flask-potion:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-fog:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-download:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-folder-times:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-folder-upload:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-fragile:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frosty-head:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-galaxy:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-game-board-alt:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gamepad-alt:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-glass-champagne:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glasses-alt:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-receiving:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-heart:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-usd:before {
  content: "";
}

.fa-hands-wash:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-head-vr:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heart-circle:before {
  content: "";
}

.fa-heart-rate:before {
  content: "";
}

.fa-heart-square:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-home-alt:before {
  content: "";
}

.fa-home-heart:before {
  content: "";
}

.fa-home-lg:before {
  content: "";
}

.fa-home-lg-alt:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-house-flood:before {
  content: "";
}

.fa-house-leave:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-house-return:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-house-user:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-humidity:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-icons-alt:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-inbox-in:before {
  content: "";
}

.fa-inbox-out:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-industry-alt:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-info-square:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-innosoft:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-inventory:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-island-tropical:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-landmark-alt:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-house:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-layer-minus:before {
  content: "";
}

.fa-layer-plus:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-location-circle:before {
  content: "";
}

.fa-location-slash:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-alt:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-lock-open-alt:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-loveseat:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luchador:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-marker-alt-slash:before {
  content: "";
}

.fa-map-marker-check:before {
  content: "";
}

.fa-map-marker-edit:before {
  content: "";
}

.fa-map-marker-exclamation:before {
  content: "";
}

.fa-map-marker-minus:before {
  content: "";
}

.fa-map-marker-plus:before {
  content: "";
}

.fa-map-marker-question:before {
  content: "";
}

.fa-map-marker-slash:before {
  content: "";
}

.fa-map-marker-smile:before {
  content: "";
}

.fa-map-marker-times:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-mind-share:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-hexagon:before {
  content: "";
}

.fa-minus-octagon:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-mobile-android:before {
  content: "";
}

.fa-mobile-android-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-money-check-edit:before {
  content: "";
}

.fa-money-check-edit-alt:before {
  content: "";
}

.fa-monitor-heart-rate:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-alt:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-music-alt:before {
  content: "";
}

.fa-music-alt-slash:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-oil-temp:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-page-break:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-brush-alt:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-pallet-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-paragraph-rtl:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-parking-circle:before {
  content: "";
}

.fa-parking-circle-slash:before {
  content: "";
}

.fa-parking-slash:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paw-alt:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-paintbrush:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-pennant:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-arrows:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-person-carry:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-laptop:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-alt:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-hexagon:before {
  content: "";
}

.fa-plus-octagon:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-portal-enter:before {
  content: "";
}

.fa-portal-exit:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-presentation:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-print-search:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-question-square:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-rabbit-fast:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-radio-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-rectangle-landscape:before {
  content: "";
}

.fa-rectangle-portrait:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-repeat-1-alt:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-retweet-alt:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salad:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-sax-hot:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-scalpel-path:before {
  content: "";
}

.fa-scanner:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-sensor-alert:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-sensor-smoke:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shipping-timed:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signal-1:before {
  content: "";
}

.fa-signal-2:before {
  content: "";
}

.fa-signal-3:before {
  content: "";
}

.fa-signal-4:before {
  content: "";
}

.fa-signal-alt:before {
  content: "";
}

.fa-signal-alt-1:before {
  content: "";
}

.fa-signal-alt-2:before {
  content: "";
}

.fa-signal-alt-3:before {
  content: "";
}

.fa-signal-alt-slash:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-signal-stream:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-ski-jump:before {
  content: "";
}

.fa-ski-lift:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sledding:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-sliders-h-square:before {
  content: "";
}

.fa-sliders-v:before {
  content: "";
}

.fa-sliders-v-square:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-plus:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snooze:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowmobile:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-circle:before {
  content: "";
}

.fa-sort-circle-down:before {
  content: "";
}

.fa-sort-circle-up:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-shapes-down:before {
  content: "";
}

.fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-sort-shapes-up:before {
  content: "";
}

.fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-sort-size-down:before {
  content: "";
}

.fa-sort-size-down-alt:before {
  content: "";
}

.fa-sort-size-up:before {
  content: "";
}

.fa-sort-size-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-soup:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-space-station-moon-alt:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-sparkles:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-starfighter-alt:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablet-android:before {
  content: "";
}

.fa-tablet-android-alt:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tachometer-alt-average:before {
  content: "";
}

.fa-tachometer-alt-fast:before {
  content: "";
}

.fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-tachometer-alt-slow:before {
  content: "";
}

.fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-tachometer-average:before {
  content: "";
}

.fa-tachometer-fast:before {
  content: "";
}

.fa-tachometer-fastest:before {
  content: "";
}

.fa-tachometer-slow:before {
  content: "";
}

.fa-tachometer-slowest:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tally:before {
  content: "";
}

.fa-tanakh:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-tasks-alt:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-temperature-down:before {
  content: "";
}

.fa-temperature-frigid:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-hot:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-temperature-up:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-thunderstorm:before {
  content: "";
}

.fa-thunderstorm-moon:before {
  content: "";
}

.fa-thunderstorm-sun:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-tilde:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-times-hexagon:before {
  content: "";
}

.fa-times-octagon:before {
  content: "";
}

.fa-times-square:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-alt:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-tombstone-alt:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-trash-undo:before {
  content: "";
}

.fa-trash-undo-alt:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-tree-alt:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-triangle-music:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-trophy-alt:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-truck-couch:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-tv-alt:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-ufo:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-usd-circle:before {
  content: "";
}

.fa-usd-square:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-chart:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-hard-hat:before {
  content: "";
}

.fa-user-headset:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-md-chat:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-class:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-crown:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-fork:before {
  content: "";
}

.fa-utensil-knife:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-utensils-alt:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vacuum:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-vhs:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-nay:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-wand-magic:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-warehouse-alt:before {
  content: "";
}

.fa-washer:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-water-lower:before {
  content: "";
}

.fa-water-rise:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-waveform-path:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-webcam:before {
  content: "";
}

.fa-webcam-slash:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wifi-1:before {
  content: "";
}

.fa-wifi-2:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-wind-warning:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-window-alt:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.7c7727ce.eot");
  src: url("fa-brands-400.7c7727ce.eot#iefix") format("embedded-opentype"), url("fa-brands-400.cbac1968.woff2") format("woff2"), url("fa-brands-400.988da569.woff") format("woff"), url("fa-brands-400.63f2abaf.ttf") format("truetype"), url("fa-brands-400.068b1299.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-duotone-900.cf304871.eot");
  src: url("fa-duotone-900.cf304871.eot#iefix") format("embedded-opentype"), url("fa-duotone-900.bfbf3e1e.woff2") format("woff2"), url("fa-duotone-900.21956cfc.woff") format("woff"), url("fa-duotone-900.69b22709.ttf") format("truetype"), url("fa-duotone-900.dbc73407.svg#fontawesome") format("svg");
}

.fad {
  font-family: "Font Awesome 5 Duotone";
  font-weight: 900;
  position: relative;
}

.fad:before {
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
  position: absolute;
}

.fad:after {
  color: var(--fa-secondary-color, inherit);
  opacity: .4;
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-swap-opacity .fad:before, .fad.fa-swap-opacity:before {
  opacity: .4;
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-swap-opacity .fad:after, .fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse {
  color: #fff;
}

.fad.fa-stack-1x, .fad.fa-stack-2x {
  position: absolute;
}

.fad.fa-stack-1x:before, .fad.fa-stack-2x:before, .fad.fa-fw:before {
  left: 50%;
  transform: translateX(-50%);
}

.fad.fa-abacus:after {
  content: "􏙀";
}

.fad.fa-acorn:after {
  content: "􏚮";
}

.fad.fa-ad:after {
  content: "􏙁";
}

.fad.fa-address-book:after {
  content: "􏊹";
}

.fad.fa-address-card:after {
  content: "􏊻";
}

.fad.fa-adjust:after {
  content: "􏁂";
}

.fad.fa-air-conditioner:after {
  content: "􏣴";
}

.fad.fa-air-freshener:after {
  content: "􏗐";
}

.fad.fa-alarm-clock:after {
  content: "􏍎";
}

.fad.fa-alarm-exclamation:after {
  content: "􏡃";
}

.fad.fa-alarm-plus:after {
  content: "􏡄";
}

.fad.fa-alarm-snooze:after {
  content: "􏡅";
}

.fad.fa-album:after {
  content: "􏢟";
}

.fad.fa-album-collection:after {
  content: "􏢠";
}

.fad.fa-alicorn:after {
  content: "􏚰";
}

.fad.fa-alien:after {
  content: "􏣵";
}

.fad.fa-alien-monster:after {
  content: "􏣶";
}

.fad.fa-align-center:after {
  content: "􏀷";
}

.fad.fa-align-justify:after {
  content: "􏀹";
}

.fad.fa-align-left:after {
  content: "􏀶";
}

.fad.fa-align-right:after {
  content: "􏀸";
}

.fad.fa-align-slash:after {
  content: "􏡆";
}

.fad.fa-allergies:after {
  content: "􏑡";
}

.fad.fa-ambulance:after {
  content: "􏃹";
}

.fad.fa-american-sign-language-interpreting:after {
  content: "􏊣";
}

.fad.fa-amp-guitar:after {
  content: "􏢡";
}

.fad.fa-analytics:after {
  content: "􏙃";
}

.fad.fa-anchor:after {
  content: "􏄽";
}

.fad.fa-angel:after {
  content: "􏝹";
}

.fad.fa-angle-double-down:after {
  content: "􏄃";
}

.fad.fa-angle-double-left:after {
  content: "􏄀";
}

.fad.fa-angle-double-right:after {
  content: "􏄁";
}

.fad.fa-angle-double-up:after {
  content: "􏄂";
}

.fad.fa-angle-down:after {
  content: "􏄇";
}

.fad.fa-angle-left:after {
  content: "􏄄";
}

.fad.fa-angle-right:after {
  content: "􏄅";
}

.fad.fa-angle-up:after {
  content: "􏄆";
}

.fad.fa-angry:after {
  content: "􏕖";
}

.fad.fa-ankh:after {
  content: "􏙄";
}

.fad.fa-apple-alt:after {
  content: "􏗑";
}

.fad.fa-apple-crate:after {
  content: "􏚱";
}

.fad.fa-archive:after {
  content: "􏆇";
}

.fad.fa-archway:after {
  content: "􏕗";
}

.fad.fa-arrow-alt-circle-down:after {
  content: "􏍘";
}

.fad.fa-arrow-alt-circle-left:after {
  content: "􏍙";
}

.fad.fa-arrow-alt-circle-right:after {
  content: "􏍚";
}

.fad.fa-arrow-alt-circle-up:after {
  content: "􏍛";
}

.fad.fa-arrow-alt-down:after {
  content: "􏍔";
}

.fad.fa-arrow-alt-from-bottom:after {
  content: "􏍆";
}

.fad.fa-arrow-alt-from-left:after {
  content: "􏍇";
}

.fad.fa-arrow-alt-from-right:after {
  content: "􏍈";
}

.fad.fa-arrow-alt-from-top:after {
  content: "􏍉";
}

.fad.fa-arrow-alt-left:after {
  content: "􏍕";
}

.fad.fa-arrow-alt-right:after {
  content: "􏍖";
}

.fad.fa-arrow-alt-square-down:after {
  content: "􏍐";
}

.fad.fa-arrow-alt-square-left:after {
  content: "􏍑";
}

.fad.fa-arrow-alt-square-right:after {
  content: "􏍒";
}

.fad.fa-arrow-alt-square-up:after {
  content: "􏍓";
}

.fad.fa-arrow-alt-to-bottom:after {
  content: "􏍊";
}

.fad.fa-arrow-alt-to-left:after {
  content: "􏍋";
}

.fad.fa-arrow-alt-to-right:after {
  content: "􏍌";
}

.fad.fa-arrow-alt-to-top:after {
  content: "􏍍";
}

.fad.fa-arrow-alt-up:after {
  content: "􏍗";
}

.fad.fa-arrow-circle-down:after {
  content: "􏂫";
}

.fad.fa-arrow-circle-left:after {
  content: "􏂨";
}

.fad.fa-arrow-circle-right:after {
  content: "􏂩";
}

.fad.fa-arrow-circle-up:after {
  content: "􏂪";
}

.fad.fa-arrow-down:after {
  content: "􏁣";
}

.fad.fa-arrow-from-bottom:after {
  content: "􏍂";
}

.fad.fa-arrow-from-left:after {
  content: "􏍃";
}

.fad.fa-arrow-from-right:after {
  content: "􏍄";
}

.fad.fa-arrow-from-top:after {
  content: "􏍅";
}

.fad.fa-arrow-left:after {
  content: "􏁠";
}

.fad.fa-arrow-right:after {
  content: "􏁡";
}

.fad.fa-arrow-square-down:after {
  content: "􏌹";
}

.fad.fa-arrow-square-left:after {
  content: "􏌺";
}

.fad.fa-arrow-square-right:after {
  content: "􏌻";
}

.fad.fa-arrow-square-up:after {
  content: "􏌼";
}

.fad.fa-arrow-to-bottom:after {
  content: "􏌽";
}

.fad.fa-arrow-to-left:after {
  content: "􏌾";
}

.fad.fa-arrow-to-right:after {
  content: "􏍀";
}

.fad.fa-arrow-to-top:after {
  content: "􏍁";
}

.fad.fa-arrow-up:after {
  content: "􏁢";
}

.fad.fa-arrows:after {
  content: "􏁇";
}

.fad.fa-arrows-alt:after {
  content: "􏂲";
}

.fad.fa-arrows-alt-h:after {
  content: "􏌷";
}

.fad.fa-arrows-alt-v:after {
  content: "􏌸";
}

.fad.fa-arrows-h:after {
  content: "􏁾";
}

.fad.fa-arrows-v:after {
  content: "􏁽";
}

.fad.fa-assistive-listening-systems:after {
  content: "􏊢";
}

.fad.fa-asterisk:after {
  content: "􏁩";
}

.fad.fa-at:after {
  content: "􏇺";
}

.fad.fa-atlas:after {
  content: "􏕘";
}

.fad.fa-atom:after {
  content: "􏗒";
}

.fad.fa-atom-alt:after {
  content: "􏗓";
}

.fad.fa-audio-description:after {
  content: "􏊞";
}

.fad.fa-award:after {
  content: "􏕙";
}

.fad.fa-axe:after {
  content: "􏚲";
}

.fad.fa-axe-battle:after {
  content: "􏚳";
}

.fad.fa-baby:after {
  content: "􏝼";
}

.fad.fa-baby-carriage:after {
  content: "􏝽";
}

.fad.fa-backpack:after {
  content: "􏗔";
}

.fad.fa-backspace:after {
  content: "􏕚";
}

.fad.fa-backward:after {
  content: "􏁊";
}

.fad.fa-bacon:after {
  content: "􏟥";
}

.fad.fa-bacteria:after {
  content: "􎁙";
}

.fad.fa-bacterium:after {
  content: "􎁚";
}

.fad.fa-badge:after {
  content: "􏌵";
}

.fad.fa-badge-check:after {
  content: "􏌶";
}

.fad.fa-badge-dollar:after {
  content: "􏙅";
}

.fad.fa-badge-percent:after {
  content: "􏙆";
}

.fad.fa-badge-sheriff:after {
  content: "􏢢";
}

.fad.fa-badger-honey:after {
  content: "􏚴";
}

.fad.fa-bags-shopping:after {
  content: "􏡇";
}

.fad.fa-bahai:after {
  content: "􏙦";
}

.fad.fa-balance-scale:after {
  content: "􏉎";
}

.fad.fa-balance-scale-left:after {
  content: "􏔕";
}

.fad.fa-balance-scale-right:after {
  content: "􏔖";
}

.fad.fa-ball-pile:after {
  content: "􏝾";
}

.fad.fa-ballot:after {
  content: "􏜲";
}

.fad.fa-ballot-check:after {
  content: "􏜳";
}

.fad.fa-ban:after {
  content: "􏁞";
}

.fad.fa-band-aid:after {
  content: "􏑢";
}

.fad.fa-banjo:after {
  content: "􏢣";
}

.fad.fa-barcode:after {
  content: "􏀪";
}

.fad.fa-barcode-alt:after {
  content: "􏑣";
}

.fad.fa-barcode-read:after {
  content: "􏑤";
}

.fad.fa-barcode-scan:after {
  content: "􏑥";
}

.fad.fa-bars:after {
  content: "􏃉";
}

.fad.fa-baseball:after {
  content: "􏐲";
}

.fad.fa-baseball-ball:after {
  content: "􏐳";
}

.fad.fa-basketball-ball:after {
  content: "􏐴";
}

.fad.fa-basketball-hoop:after {
  content: "􏐵";
}

.fad.fa-bat:after {
  content: "􏚵";
}

.fad.fa-bath:after {
  content: "􏋍";
}

.fad.fa-battery-bolt:after {
  content: "􏍶";
}

.fad.fa-battery-empty:after {
  content: "􏉄";
}

.fad.fa-battery-full:after {
  content: "􏉀";
}

.fad.fa-battery-half:after {
  content: "􏉂";
}

.fad.fa-battery-quarter:after {
  content: "􏉃";
}

.fad.fa-battery-slash:after {
  content: "􏍷";
}

.fad.fa-battery-three-quarters:after {
  content: "􏉁";
}

.fad.fa-bed:after {
  content: "􏈶";
}

.fad.fa-bed-alt:after {
  content: "􏣷";
}

.fad.fa-bed-bunk:after {
  content: "􏣸";
}

.fad.fa-bed-empty:after {
  content: "􏣹";
}

.fad.fa-beer:after {
  content: "􏃼";
}

.fad.fa-bell:after {
  content: "􏃳";
}

.fad.fa-bell-exclamation:after {
  content: "􏡈";
}

.fad.fa-bell-on:after {
  content: "􏣺";
}

.fad.fa-bell-plus:after {
  content: "􏡉";
}

.fad.fa-bell-school:after {
  content: "􏗕";
}

.fad.fa-bell-school-slash:after {
  content: "􏗖";
}

.fad.fa-bell-slash:after {
  content: "􏇶";
}

.fad.fa-bells:after {
  content: "􏝿";
}

.fad.fa-betamax:after {
  content: "􏢤";
}

.fad.fa-bezier-curve:after {
  content: "􏕛";
}

.fad.fa-bible:after {
  content: "􏙇";
}

.fad.fa-bicycle:after {
  content: "􏈆";
}

.fad.fa-biking:after {
  content: "􏡊";
}

.fad.fa-biking-mountain:after {
  content: "􏡋";
}

.fad.fa-binoculars:after {
  content: "􏇥";
}

.fad.fa-biohazard:after {
  content: "􏞀";
}

.fad.fa-birthday-cake:after {
  content: "􏇽";
}

.fad.fa-blanket:after {
  content: "􏒘";
}

.fad.fa-blender:after {
  content: "􏔗";
}

.fad.fa-blender-phone:after {
  content: "􏚶";
}

.fad.fa-blind:after {
  content: "􏊝";
}

.fad.fa-blinds:after {
  content: "􏣻";
}

.fad.fa-blinds-open:after {
  content: "􏣼";
}

.fad.fa-blinds-raised:after {
  content: "􏣽";
}

.fad.fa-blog:after {
  content: "􏞁";
}

.fad.fa-bold:after {
  content: "􏀲";
}

.fad.fa-bolt:after {
  content: "􏃧";
}

.fad.fa-bomb:after {
  content: "􏇢";
}

.fad.fa-bone:after {
  content: "􏗗";
}

.fad.fa-bone-break:after {
  content: "􏗘";
}

.fad.fa-bong:after {
  content: "􏕜";
}

.fad.fa-book:after {
  content: "􏀭";
}

.fad.fa-book-alt:after {
  content: "􏗙";
}

.fad.fa-book-dead:after {
  content: "􏚷";
}

.fad.fa-book-heart:after {
  content: "􏒙";
}

.fad.fa-book-medical:after {
  content: "􏟦";
}

.fad.fa-book-open:after {
  content: "􏔘";
}

.fad.fa-book-reader:after {
  content: "􏗚";
}

.fad.fa-book-spells:after {
  content: "􏚸";
}

.fad.fa-book-user:after {
  content: "􏟧";
}

.fad.fa-bookmark:after {
  content: "􏀮";
}

.fad.fa-books:after {
  content: "􏗛";
}

.fad.fa-books-medical:after {
  content: "􏟨";
}

.fad.fa-boombox:after {
  content: "􏢥";
}

.fad.fa-boot:after {
  content: "􏞂";
}

.fad.fa-booth-curtain:after {
  content: "􏜴";
}

.fad.fa-border-all:after {
  content: "􏡌";
}

.fad.fa-border-bottom:after {
  content: "􏡍";
}

.fad.fa-border-center-h:after {
  content: "􏢜";
}

.fad.fa-border-center-v:after {
  content: "􏢝";
}

.fad.fa-border-inner:after {
  content: "􏡎";
}

.fad.fa-border-left:after {
  content: "􏡏";
}

.fad.fa-border-none:after {
  content: "􏡐";
}

.fad.fa-border-outer:after {
  content: "􏡑";
}

.fad.fa-border-right:after {
  content: "􏡒";
}

.fad.fa-border-style:after {
  content: "􏡓";
}

.fad.fa-border-style-alt:after {
  content: "􏡔";
}

.fad.fa-border-top:after {
  content: "􏡕";
}

.fad.fa-bow-arrow:after {
  content: "􏚹";
}

.fad.fa-bowling-ball:after {
  content: "􏐶";
}

.fad.fa-bowling-pins:after {
  content: "􏐷";
}

.fad.fa-box:after {
  content: "􏑦";
}

.fad.fa-box-alt:after {
  content: "􏒚";
}

.fad.fa-box-ballot:after {
  content: "􏜵";
}

.fad.fa-box-check:after {
  content: "􏑧";
}

.fad.fa-box-fragile:after {
  content: "􏒛";
}

.fad.fa-box-full:after {
  content: "􏒜";
}

.fad.fa-box-heart:after {
  content: "􏒝";
}

.fad.fa-box-open:after {
  content: "􏒞";
}

.fad.fa-box-tissue:after {
  content: "􎁛";
}

.fad.fa-box-up:after {
  content: "􏒟";
}

.fad.fa-box-usd:after {
  content: "􏒠";
}

.fad.fa-boxes:after {
  content: "􏑨";
}

.fad.fa-boxes-alt:after {
  content: "􏒡";
}

.fad.fa-boxing-glove:after {
  content: "􏐸";
}

.fad.fa-brackets:after {
  content: "􏟩";
}

.fad.fa-brackets-curly:after {
  content: "􏟪";
}

.fad.fa-braille:after {
  content: "􏊡";
}

.fad.fa-brain:after {
  content: "􏗜";
}

.fad.fa-bread-loaf:after {
  content: "􏟫";
}

.fad.fa-bread-slice:after {
  content: "􏟬";
}

.fad.fa-briefcase:after {
  content: "􏂱";
}

.fad.fa-briefcase-medical:after {
  content: "􏑩";
}

.fad.fa-bring-forward:after {
  content: "􏡖";
}

.fad.fa-bring-front:after {
  content: "􏡗";
}

.fad.fa-broadcast-tower:after {
  content: "􏔙";
}

.fad.fa-broom:after {
  content: "􏔚";
}

.fad.fa-browser:after {
  content: "􏍾";
}

.fad.fa-brush:after {
  content: "􏕝";
}

.fad.fa-bug:after {
  content: "􏆈";
}

.fad.fa-building:after {
  content: "􏆭";
}

.fad.fa-bullhorn:after {
  content: "􏂡";
}

.fad.fa-bullseye:after {
  content: "􏅀";
}

.fad.fa-bullseye-arrow:after {
  content: "􏙈";
}

.fad.fa-bullseye-pointer:after {
  content: "􏙉";
}

.fad.fa-burger-soda:after {
  content: "􏡘";
}

.fad.fa-burn:after {
  content: "􏑪";
}

.fad.fa-burrito:after {
  content: "􏟭";
}

.fad.fa-bus:after {
  content: "􏈇";
}

.fad.fa-bus-alt:after {
  content: "􏕞";
}

.fad.fa-bus-school:after {
  content: "􏗝";
}

.fad.fa-business-time:after {
  content: "􏙊";
}

.fad.fa-cabinet-filing:after {
  content: "􏙋";
}

.fad.fa-cactus:after {
  content: "􏢧";
}

.fad.fa-calculator:after {
  content: "􏇬";
}

.fad.fa-calculator-alt:after {
  content: "􏙌";
}

.fad.fa-calendar:after {
  content: "􏄳";
}

.fad.fa-calendar-alt:after {
  content: "􏁳";
}

.fad.fa-calendar-check:after {
  content: "􏉴";
}

.fad.fa-calendar-day:after {
  content: "􏞃";
}

.fad.fa-calendar-edit:after {
  content: "􏌳";
}

.fad.fa-calendar-exclamation:after {
  content: "􏌴";
}

.fad.fa-calendar-minus:after {
  content: "􏉲";
}

.fad.fa-calendar-plus:after {
  content: "􏉱";
}

.fad.fa-calendar-star:after {
  content: "􏜶";
}

.fad.fa-calendar-times:after {
  content: "􏉳";
}

.fad.fa-calendar-week:after {
  content: "􏞄";
}

.fad.fa-camcorder:after {
  content: "􏢨";
}

.fad.fa-camera:after {
  content: "􏀰";
}

.fad.fa-camera-alt:after {
  content: "􏌲";
}

.fad.fa-camera-home:after {
  content: "􏣾";
}

.fad.fa-camera-movie:after {
  content: "􏢩";
}

.fad.fa-camera-polaroid:after {
  content: "􏢪";
}

.fad.fa-camera-retro:after {
  content: "􏂃";
}

.fad.fa-campfire:after {
  content: "􏚺";
}

.fad.fa-campground:after {
  content: "􏚻";
}

.fad.fa-candle-holder:after {
  content: "􏚼";
}

.fad.fa-candy-cane:after {
  content: "􏞆";
}

.fad.fa-candy-corn:after {
  content: "􏚽";
}

.fad.fa-cannabis:after {
  content: "􏕟";
}

.fad.fa-capsules:after {
  content: "􏑫";
}

.fad.fa-car:after {
  content: "􏆹";
}

.fad.fa-car-alt:after {
  content: "􏗞";
}

.fad.fa-car-battery:after {
  content: "􏗟";
}

.fad.fa-car-building:after {
  content: "􏡙";
}

.fad.fa-car-bump:after {
  content: "􏗠";
}

.fad.fa-car-bus:after {
  content: "􏡚";
}

.fad.fa-car-crash:after {
  content: "􏗡";
}

.fad.fa-car-garage:after {
  content: "􏗢";
}

.fad.fa-car-mechanic:after {
  content: "􏗣";
}

.fad.fa-car-side:after {
  content: "􏗤";
}

.fad.fa-car-tilt:after {
  content: "􏗥";
}

.fad.fa-car-wash:after {
  content: "􏗦";
}

.fad.fa-caravan:after {
  content: "􏣿";
}

.fad.fa-caravan-alt:after {
  content: "􎀀";
}

.fad.fa-caret-circle-down:after {
  content: "􏌭";
}

.fad.fa-caret-circle-left:after {
  content: "􏌮";
}

.fad.fa-caret-circle-right:after {
  content: "􏌰";
}

.fad.fa-caret-circle-up:after {
  content: "􏌱";
}

.fad.fa-caret-down:after {
  content: "􏃗";
}

.fad.fa-caret-left:after {
  content: "􏃙";
}

.fad.fa-caret-right:after {
  content: "􏃚";
}

.fad.fa-caret-square-down:after {
  content: "􏅐";
}

.fad.fa-caret-square-left:after {
  content: "􏆑";
}

.fad.fa-caret-square-right:after {
  content: "􏅒";
}

.fad.fa-caret-square-up:after {
  content: "􏅑";
}

.fad.fa-caret-up:after {
  content: "􏃘";
}

.fad.fa-carrot:after {
  content: "􏞇";
}

.fad.fa-cars:after {
  content: "􏡛";
}

.fad.fa-cart-arrow-down:after {
  content: "􏈘";
}

.fad.fa-cart-plus:after {
  content: "􏈗";
}

.fad.fa-cash-register:after {
  content: "􏞈";
}

.fad.fa-cassette-tape:after {
  content: "􏢫";
}

.fad.fa-cat:after {
  content: "􏚾";
}

.fad.fa-cat-space:after {
  content: "􎀁";
}

.fad.fa-cauldron:after {
  content: "􏚿";
}

.fad.fa-cctv:after {
  content: "􏢬";
}

.fad.fa-certificate:after {
  content: "􏂣";
}

.fad.fa-chair:after {
  content: "􏛀";
}

.fad.fa-chair-office:after {
  content: "􏛁";
}

.fad.fa-chalkboard:after {
  content: "􏔛";
}

.fad.fa-chalkboard-teacher:after {
  content: "􏔜";
}

.fad.fa-charging-station:after {
  content: "􏗧";
}

.fad.fa-chart-area:after {
  content: "􏇾";
}

.fad.fa-chart-bar:after {
  content: "􏂀";
}

.fad.fa-chart-line:after {
  content: "􏈁";
}

.fad.fa-chart-line-down:after {
  content: "􏙍";
}

.fad.fa-chart-network:after {
  content: "􏞊";
}

.fad.fa-chart-pie:after {
  content: "􏈀";
}

.fad.fa-chart-pie-alt:after {
  content: "􏙎";
}

.fad.fa-chart-scatter:after {
  content: "􏟮";
}

.fad.fa-check:after {
  content: "􏀌";
}

.fad.fa-check-circle:after {
  content: "􏁘";
}

.fad.fa-check-double:after {
  content: "􏕠";
}

.fad.fa-check-square:after {
  content: "􏅊";
}

.fad.fa-cheese:after {
  content: "􏟯";
}

.fad.fa-cheese-swiss:after {
  content: "􏟰";
}

.fad.fa-cheeseburger:after {
  content: "􏟱";
}

.fad.fa-chess:after {
  content: "􏐹";
}

.fad.fa-chess-bishop:after {
  content: "􏐺";
}

.fad.fa-chess-bishop-alt:after {
  content: "􏐻";
}

.fad.fa-chess-board:after {
  content: "􏐼";
}

.fad.fa-chess-clock:after {
  content: "􏐽";
}

.fad.fa-chess-clock-alt:after {
  content: "􏐾";
}

.fad.fa-chess-king:after {
  content: "􏐿";
}

.fad.fa-chess-king-alt:after {
  content: "􏑀";
}

.fad.fa-chess-knight:after {
  content: "􏑁";
}

.fad.fa-chess-knight-alt:after {
  content: "􏑂";
}

.fad.fa-chess-pawn:after {
  content: "􏑃";
}

.fad.fa-chess-pawn-alt:after {
  content: "􏑄";
}

.fad.fa-chess-queen:after {
  content: "􏑅";
}

.fad.fa-chess-queen-alt:after {
  content: "􏑆";
}

.fad.fa-chess-rook:after {
  content: "􏑇";
}

.fad.fa-chess-rook-alt:after {
  content: "􏑈";
}

.fad.fa-chevron-circle-down:after {
  content: "􏄺";
}

.fad.fa-chevron-circle-left:after {
  content: "􏄷";
}

.fad.fa-chevron-circle-right:after {
  content: "􏄸";
}

.fad.fa-chevron-circle-up:after {
  content: "􏄹";
}

.fad.fa-chevron-double-down:after {
  content: "􏌢";
}

.fad.fa-chevron-double-left:after {
  content: "􏌣";
}

.fad.fa-chevron-double-right:after {
  content: "􏌤";
}

.fad.fa-chevron-double-up:after {
  content: "􏌥";
}

.fad.fa-chevron-down:after {
  content: "􏁸";
}

.fad.fa-chevron-left:after {
  content: "􏁓";
}

.fad.fa-chevron-right:after {
  content: "􏁔";
}

.fad.fa-chevron-square-down:after {
  content: "􏌩";
}

.fad.fa-chevron-square-left:after {
  content: "􏌪";
}

.fad.fa-chevron-square-right:after {
  content: "􏌫";
}

.fad.fa-chevron-square-up:after {
  content: "􏌬";
}

.fad.fa-chevron-up:after {
  content: "􏁷";
}

.fad.fa-child:after {
  content: "􏆮";
}

.fad.fa-chimney:after {
  content: "􏞋";
}

.fad.fa-church:after {
  content: "􏔝";
}

.fad.fa-circle:after {
  content: "􏄑";
}

.fad.fa-circle-notch:after {
  content: "􏇎";
}

.fad.fa-city:after {
  content: "􏙏";
}

.fad.fa-clarinet:after {
  content: "􏢭";
}

.fad.fa-claw-marks:after {
  content: "􏛂";
}

.fad.fa-clinic-medical:after {
  content: "􏟲";
}

.fad.fa-clipboard:after {
  content: "􏌨";
}

.fad.fa-clipboard-check:after {
  content: "􏑬";
}

.fad.fa-clipboard-list:after {
  content: "􏑭";
}

.fad.fa-clipboard-list-check:after {
  content: "􏜷";
}

.fad.fa-clipboard-prescription:after {
  content: "􏗨";
}

.fad.fa-clipboard-user:after {
  content: "􏟳";
}

.fad.fa-clock:after {
  content: "􏀗";
}

.fad.fa-clone:after {
  content: "􏉍";
}

.fad.fa-closed-captioning:after {
  content: "􏈊";
}

.fad.fa-cloud:after {
  content: "􏃂";
}

.fad.fa-cloud-download:after {
  content: "􏃭";
}

.fad.fa-cloud-download-alt:after {
  content: "􏎁";
}

.fad.fa-cloud-drizzle:after {
  content: "􏜸";
}

.fad.fa-cloud-hail:after {
  content: "􏜹";
}

.fad.fa-cloud-hail-mixed:after {
  content: "􏜺";
}

.fad.fa-cloud-meatball:after {
  content: "􏜻";
}

.fad.fa-cloud-moon:after {
  content: "􏛃";
}

.fad.fa-cloud-moon-rain:after {
  content: "􏜼";
}

.fad.fa-cloud-music:after {
  content: "􏢮";
}

.fad.fa-cloud-rain:after {
  content: "􏜽";
}

.fad.fa-cloud-rainbow:after {
  content: "􏜾";
}

.fad.fa-cloud-showers:after {
  content: "􏜿";
}

.fad.fa-cloud-showers-heavy:after {
  content: "􏝀";
}

.fad.fa-cloud-sleet:after {
  content: "􏝁";
}

.fad.fa-cloud-snow:after {
  content: "􏝂";
}

.fad.fa-cloud-sun:after {
  content: "􏛄";
}

.fad.fa-cloud-sun-rain:after {
  content: "􏝃";
}

.fad.fa-cloud-upload:after {
  content: "􏃮";
}

.fad.fa-cloud-upload-alt:after {
  content: "􏎂";
}

.fad.fa-clouds:after {
  content: "􏝄";
}

.fad.fa-clouds-moon:after {
  content: "􏝅";
}

.fad.fa-clouds-sun:after {
  content: "􏝆";
}

.fad.fa-club:after {
  content: "􏌧";
}

.fad.fa-cocktail:after {
  content: "􏕡";
}

.fad.fa-code:after {
  content: "􏄡";
}

.fad.fa-code-branch:after {
  content: "􏄦";
}

.fad.fa-code-commit:after {
  content: "􏎆";
}

.fad.fa-code-merge:after {
  content: "􏎇";
}

.fad.fa-coffee:after {
  content: "􏃴";
}

.fad.fa-coffee-pot:after {
  content: "􎀂";
}

.fad.fa-coffee-togo:after {
  content: "􏛅";
}

.fad.fa-coffin:after {
  content: "􏛆";
}

.fad.fa-coffin-cross:after {
  content: "􎁑";
}

.fad.fa-cog:after {
  content: "􏀓";
}

.fad.fa-cogs:after {
  content: "􏂅";
}

.fad.fa-coin:after {
  content: "􏡜";
}

.fad.fa-coins:after {
  content: "􏔞";
}

.fad.fa-columns:after {
  content: "􏃛";
}

.fad.fa-comet:after {
  content: "􎀃";
}

.fad.fa-comment:after {
  content: "􏁵";
}

.fad.fa-comment-alt:after {
  content: "􏉺";
}

.fad.fa-comment-alt-check:after {
  content: "􏒢";
}

.fad.fa-comment-alt-dollar:after {
  content: "􏙐";
}

.fad.fa-comment-alt-dots:after {
  content: "􏒣";
}

.fad.fa-comment-alt-edit:after {
  content: "􏒤";
}

.fad.fa-comment-alt-exclamation:after {
  content: "􏒥";
}

.fad.fa-comment-alt-lines:after {
  content: "􏒦";
}

.fad.fa-comment-alt-medical:after {
  content: "􏟴";
}

.fad.fa-comment-alt-minus:after {
  content: "􏒧";
}

.fad.fa-comment-alt-music:after {
  content: "􏢯";
}

.fad.fa-comment-alt-plus:after {
  content: "􏒨";
}

.fad.fa-comment-alt-slash:after {
  content: "􏒩";
}

.fad.fa-comment-alt-smile:after {
  content: "􏒪";
}

.fad.fa-comment-alt-times:after {
  content: "􏒫";
}

.fad.fa-comment-check:after {
  content: "􏒬";
}

.fad.fa-comment-dollar:after {
  content: "􏙑";
}

.fad.fa-comment-dots:after {
  content: "􏒭";
}

.fad.fa-comment-edit:after {
  content: "􏒮";
}

.fad.fa-comment-exclamation:after {
  content: "􏒯";
}

.fad.fa-comment-lines:after {
  content: "􏒰";
}

.fad.fa-comment-medical:after {
  content: "􏟵";
}

.fad.fa-comment-minus:after {
  content: "􏒱";
}

.fad.fa-comment-music:after {
  content: "􏢰";
}

.fad.fa-comment-plus:after {
  content: "􏒲";
}

.fad.fa-comment-slash:after {
  content: "􏒳";
}

.fad.fa-comment-smile:after {
  content: "􏒴";
}

.fad.fa-comment-times:after {
  content: "􏒵";
}

.fad.fa-comments:after {
  content: "􏂆";
}

.fad.fa-comments-alt:after {
  content: "􏒶";
}

.fad.fa-comments-alt-dollar:after {
  content: "􏙒";
}

.fad.fa-comments-dollar:after {
  content: "􏙓";
}

.fad.fa-compact-disc:after {
  content: "􏔟";
}

.fad.fa-compass:after {
  content: "􏅎";
}

.fad.fa-compass-slash:after {
  content: "􏗩";
}

.fad.fa-compress:after {
  content: "􏁦";
}

.fad.fa-compress-alt:after {
  content: "􏐢";
}

.fad.fa-compress-arrows-alt:after {
  content: "􏞌";
}

.fad.fa-compress-wide:after {
  content: "􏌦";
}

.fad.fa-computer-classic:after {
  content: "􏢱";
}

.fad.fa-computer-speaker:after {
  content: "􏢲";
}

.fad.fa-concierge-bell:after {
  content: "􏕢";
}

.fad.fa-construction:after {
  content: "􏡝";
}

.fad.fa-container-storage:after {
  content: "􏒷";
}

.fad.fa-conveyor-belt:after {
  content: "􏑮";
}

.fad.fa-conveyor-belt-alt:after {
  content: "􏑯";
}

.fad.fa-cookie:after {
  content: "􏕣";
}

.fad.fa-cookie-bite:after {
  content: "􏕤";
}

.fad.fa-copy:after {
  content: "􏃅";
}

.fad.fa-copyright:after {
  content: "􏇹";
}

.fad.fa-corn:after {
  content: "􏛇";
}

.fad.fa-couch:after {
  content: "􏒸";
}

.fad.fa-cow:after {
  content: "􏛈";
}

.fad.fa-cowbell:after {
  content: "􏢳";
}

.fad.fa-cowbell-more:after {
  content: "􏢴";
}

.fad.fa-credit-card:after {
  content: "􏂝";
}

.fad.fa-credit-card-blank:after {
  content: "􏎉";
}

.fad.fa-credit-card-front:after {
  content: "􏎊";
}

.fad.fa-cricket:after {
  content: "􏑉";
}

.fad.fa-croissant:after {
  content: "􏟶";
}

.fad.fa-crop:after {
  content: "􏄥";
}

.fad.fa-crop-alt:after {
  content: "􏕥";
}

.fad.fa-cross:after {
  content: "􏙔";
}

.fad.fa-crosshairs:after {
  content: "􏁛";
}

.fad.fa-crow:after {
  content: "􏔠";
}

.fad.fa-crown:after {
  content: "􏔡";
}

.fad.fa-crutch:after {
  content: "􏟷";
}

.fad.fa-crutches:after {
  content: "􏟸";
}

.fad.fa-cube:after {
  content: "􏆲";
}

.fad.fa-cubes:after {
  content: "􏆳";
}

.fad.fa-curling:after {
  content: "􏑊";
}

.fad.fa-cut:after {
  content: "􏃄";
}

.fad.fa-dagger:after {
  content: "􏛋";
}

.fad.fa-database:after {
  content: "􏇀";
}

.fad.fa-deaf:after {
  content: "􏊤";
}

.fad.fa-debug:after {
  content: "􏟹";
}

.fad.fa-deer:after {
  content: "􏞎";
}

.fad.fa-deer-rudolph:after {
  content: "􏞏";
}

.fad.fa-democrat:after {
  content: "􏝇";
}

.fad.fa-desktop:after {
  content: "􏄈";
}

.fad.fa-desktop-alt:after {
  content: "􏎐";
}

.fad.fa-dewpoint:after {
  content: "􏝈";
}

.fad.fa-dharmachakra:after {
  content: "􏙕";
}

.fad.fa-diagnoses:after {
  content: "􏑰";
}

.fad.fa-diamond:after {
  content: "􏈙";
}

.fad.fa-dice:after {
  content: "􏔢";
}

.fad.fa-dice-d10:after {
  content: "􏛍";
}

.fad.fa-dice-d12:after {
  content: "􏛎";
}

.fad.fa-dice-d20:after {
  content: "􏛏";
}

.fad.fa-dice-d4:after {
  content: "􏛐";
}

.fad.fa-dice-d6:after {
  content: "􏛑";
}

.fad.fa-dice-d8:after {
  content: "􏛒";
}

.fad.fa-dice-five:after {
  content: "􏔣";
}

.fad.fa-dice-four:after {
  content: "􏔤";
}

.fad.fa-dice-one:after {
  content: "􏔥";
}

.fad.fa-dice-six:after {
  content: "􏔦";
}

.fad.fa-dice-three:after {
  content: "􏔧";
}

.fad.fa-dice-two:after {
  content: "􏔨";
}

.fad.fa-digging:after {
  content: "􏡞";
}

.fad.fa-digital-tachograph:after {
  content: "􏕦";
}

.fad.fa-diploma:after {
  content: "􏗪";
}

.fad.fa-directions:after {
  content: "􏗫";
}

.fad.fa-disc-drive:after {
  content: "􏢵";
}

.fad.fa-disease:after {
  content: "􏟺";
}

.fad.fa-divide:after {
  content: "􏔩";
}

.fad.fa-dizzy:after {
  content: "􏕧";
}

.fad.fa-dna:after {
  content: "􏑱";
}

.fad.fa-do-not-enter:after {
  content: "􏗬";
}

.fad.fa-dog:after {
  content: "􏛓";
}

.fad.fa-dog-leashed:after {
  content: "􏛔";
}

.fad.fa-dollar-sign:after {
  content: "􏅕";
}

.fad.fa-dolly:after {
  content: "􏑲";
}

.fad.fa-dolly-empty:after {
  content: "􏑳";
}

.fad.fa-dolly-flatbed:after {
  content: "􏑴";
}

.fad.fa-dolly-flatbed-alt:after {
  content: "􏑵";
}

.fad.fa-dolly-flatbed-empty:after {
  content: "􏑶";
}

.fad.fa-donate:after {
  content: "􏒹";
}

.fad.fa-door-closed:after {
  content: "􏔪";
}

.fad.fa-door-open:after {
  content: "􏔫";
}

.fad.fa-dot-circle:after {
  content: "􏆒";
}

.fad.fa-dove:after {
  content: "􏒺";
}

.fad.fa-download:after {
  content: "􏀙";
}

.fad.fa-drafting-compass:after {
  content: "􏕨";
}

.fad.fa-dragon:after {
  content: "􏛕";
}

.fad.fa-draw-circle:after {
  content: "􏗭";
}

.fad.fa-draw-polygon:after {
  content: "􏗮";
}

.fad.fa-draw-square:after {
  content: "􏗯";
}

.fad.fa-dreidel:after {
  content: "􏞒";
}

.fad.fa-drone:after {
  content: "􏡟";
}

.fad.fa-drone-alt:after {
  content: "􏡠";
}

.fad.fa-drum:after {
  content: "􏕩";
}

.fad.fa-drum-steelpan:after {
  content: "􏕪";
}

.fad.fa-drumstick:after {
  content: "􏛖";
}

.fad.fa-drumstick-bite:after {
  content: "􏛗";
}

.fad.fa-dryer:after {
  content: "􏡡";
}

.fad.fa-dryer-alt:after {
  content: "􏡢";
}

.fad.fa-duck:after {
  content: "􏛘";
}

.fad.fa-dumbbell:after {
  content: "􏑋";
}

.fad.fa-dumpster:after {
  content: "􏞓";
}

.fad.fa-dumpster-fire:after {
  content: "􏞔";
}

.fad.fa-dungeon:after {
  content: "􏛙";
}

.fad.fa-ear:after {
  content: "􏗰";
}

.fad.fa-ear-muffs:after {
  content: "􏞕";
}

.fad.fa-eclipse:after {
  content: "􏝉";
}

.fad.fa-eclipse-alt:after {
  content: "􏝊";
}

.fad.fa-edit:after {
  content: "􏁄";
}

.fad.fa-egg:after {
  content: "􏟻";
}

.fad.fa-egg-fried:after {
  content: "􏟼";
}

.fad.fa-eject:after {
  content: "􏁒";
}

.fad.fa-elephant:after {
  content: "􏛚";
}

.fad.fa-ellipsis-h:after {
  content: "􏅁";
}

.fad.fa-ellipsis-h-alt:after {
  content: "􏎛";
}

.fad.fa-ellipsis-v:after {
  content: "􏅂";
}

.fad.fa-ellipsis-v-alt:after {
  content: "􏎜";
}

.fad.fa-empty-set:after {
  content: "􏙖";
}

.fad.fa-engine-warning:after {
  content: "􏗲";
}

.fad.fa-envelope:after {
  content: "􏃠";
}

.fad.fa-envelope-open:after {
  content: "􏊶";
}

.fad.fa-envelope-open-dollar:after {
  content: "􏙗";
}

.fad.fa-envelope-open-text:after {
  content: "􏙘";
}

.fad.fa-envelope-square:after {
  content: "􏆙";
}

.fad.fa-equals:after {
  content: "􏔬";
}

.fad.fa-eraser:after {
  content: "􏄭";
}

.fad.fa-ethernet:after {
  content: "􏞖";
}

.fad.fa-euro-sign:after {
  content: "􏅓";
}

.fad.fa-exchange:after {
  content: "􏃬";
}

.fad.fa-exchange-alt:after {
  content: "􏍢";
}

.fad.fa-exclamation:after {
  content: "􏄪";
}

.fad.fa-exclamation-circle:after {
  content: "􏁪";
}

.fad.fa-exclamation-square:after {
  content: "􏌡";
}

.fad.fa-exclamation-triangle:after {
  content: "􏁱";
}

.fad.fa-expand:after {
  content: "􏁥";
}

.fad.fa-expand-alt:after {
  content: "􏐤";
}

.fad.fa-expand-arrows:after {
  content: "􏌝";
}

.fad.fa-expand-arrows-alt:after {
  content: "􏌞";
}

.fad.fa-expand-wide:after {
  content: "􏌠";
}

.fad.fa-external-link:after {
  content: "􏂎";
}

.fad.fa-external-link-alt:after {
  content: "􏍝";
}

.fad.fa-external-link-square:after {
  content: "􏅌";
}

.fad.fa-external-link-square-alt:after {
  content: "􏍠";
}

.fad.fa-eye:after {
  content: "􏁮";
}

.fad.fa-eye-dropper:after {
  content: "􏇻";
}

.fad.fa-eye-evil:after {
  content: "􏛛";
}

.fad.fa-eye-slash:after {
  content: "􏁰";
}

.fad.fa-fan:after {
  content: "􏡣";
}

.fad.fa-fan-table:after {
  content: "􎀄";
}

.fad.fa-farm:after {
  content: "􏡤";
}

.fad.fa-fast-backward:after {
  content: "􏁉";
}

.fad.fa-fast-forward:after {
  content: "􏁐";
}

.fad.fa-faucet:after {
  content: "􎀅";
}

.fad.fa-faucet-drip:after {
  content: "􎀆";
}

.fad.fa-fax:after {
  content: "􏆬";
}

.fad.fa-feather:after {
  content: "􏔭";
}

.fad.fa-feather-alt:after {
  content: "􏕫";
}

.fad.fa-female:after {
  content: "􏆂";
}

.fad.fa-field-hockey:after {
  content: "􏑌";
}

.fad.fa-fighter-jet:after {
  content: "􏃻";
}

.fad.fa-file:after {
  content: "􏅛";
}

.fad.fa-file-alt:after {
  content: "􏅜";
}

.fad.fa-file-archive:after {
  content: "􏇆";
}

.fad.fa-file-audio:after {
  content: "􏇇";
}

.fad.fa-file-certificate:after {
  content: "􏗳";
}

.fad.fa-file-chart-line:after {
  content: "􏙙";
}

.fad.fa-file-chart-pie:after {
  content: "􏙚";
}

.fad.fa-file-check:after {
  content: "􏌖";
}

.fad.fa-file-code:after {
  content: "􏇉";
}

.fad.fa-file-contract:after {
  content: "􏕬";
}

.fad.fa-file-csv:after {
  content: "􏛝";
}

.fad.fa-file-download:after {
  content: "􏕭";
}

.fad.fa-file-edit:after {
  content: "􏌜";
}

.fad.fa-file-excel:after {
  content: "􏇃";
}

.fad.fa-file-exclamation:after {
  content: "􏌚";
}

.fad.fa-file-export:after {
  content: "􏕮";
}

.fad.fa-file-image:after {
  content: "􏇅";
}

.fad.fa-file-import:after {
  content: "􏕯";
}

.fad.fa-file-invoice:after {
  content: "􏕰";
}

.fad.fa-file-invoice-dollar:after {
  content: "􏕱";
}

.fad.fa-file-medical:after {
  content: "􏑷";
}

.fad.fa-file-medical-alt:after {
  content: "􏑸";
}

.fad.fa-file-minus:after {
  content: "􏌘";
}

.fad.fa-file-music:after {
  content: "􏢶";
}

.fad.fa-file-pdf:after {
  content: "􏇁";
}

.fad.fa-file-plus:after {
  content: "􏌙";
}

.fad.fa-file-powerpoint:after {
  content: "􏇄";
}

.fad.fa-file-prescription:after {
  content: "􏕲";
}

.fad.fa-file-search:after {
  content: "􏡥";
}

.fad.fa-file-signature:after {
  content: "􏕳";
}

.fad.fa-file-spreadsheet:after {
  content: "􏙛";
}

.fad.fa-file-times:after {
  content: "􏌗";
}

.fad.fa-file-upload:after {
  content: "􏕴";
}

.fad.fa-file-user:after {
  content: "􏙜";
}

.fad.fa-file-video:after {
  content: "􏇈";
}

.fad.fa-file-word:after {
  content: "􏇂";
}

.fad.fa-files-medical:after {
  content: "􏟽";
}

.fad.fa-fill:after {
  content: "􏕵";
}

.fad.fa-fill-drip:after {
  content: "􏕶";
}

.fad.fa-film:after {
  content: "􏀈";
}

.fad.fa-film-alt:after {
  content: "􏎠";
}

.fad.fa-film-canister:after {
  content: "􏢷";
}

.fad.fa-filter:after {
  content: "􏂰";
}

.fad.fa-fingerprint:after {
  content: "􏕷";
}

.fad.fa-fire:after {
  content: "􏁭";
}

.fad.fa-fire-alt:after {
  content: "􏟤";
}

.fad.fa-fire-extinguisher:after {
  content: "􏄴";
}

.fad.fa-fire-smoke:after {
  content: "􏝋";
}

.fad.fa-fireplace:after {
  content: "􏞚";
}

.fad.fa-first-aid:after {
  content: "􏑹";
}

.fad.fa-fish:after {
  content: "􏕸";
}

.fad.fa-fish-cooked:after {
  content: "􏟾";
}

.fad.fa-fist-raised:after {
  content: "􏛞";
}

.fad.fa-flag:after {
  content: "􏀤";
}

.fad.fa-flag-alt:after {
  content: "􏝌";
}

.fad.fa-flag-checkered:after {
  content: "􏄞";
}

.fad.fa-flag-usa:after {
  content: "􏝍";
}

.fad.fa-flame:after {
  content: "􏛟";
}

.fad.fa-flashlight:after {
  content: "􏢸";
}

.fad.fa-flask:after {
  content: "􏃃";
}

.fad.fa-flask-poison:after {
  content: "􏛠";
}

.fad.fa-flask-potion:after {
  content: "􏛡";
}

.fad.fa-flower:after {
  content: "􏟿";
}

.fad.fa-flower-daffodil:after {
  content: "􏠀";
}

.fad.fa-flower-tulip:after {
  content: "􏠁";
}

.fad.fa-flushed:after {
  content: "􏕹";
}

.fad.fa-flute:after {
  content: "􏢹";
}

.fad.fa-flux-capacitor:after {
  content: "􏢺";
}

.fad.fa-fog:after {
  content: "􏝎";
}

.fad.fa-folder:after {
  content: "􏁻";
}

.fad.fa-folder-download:after {
  content: "􎁓";
}

.fad.fa-folder-minus:after {
  content: "􏙝";
}

.fad.fa-folder-open:after {
  content: "􏁼";
}

.fad.fa-folder-plus:after {
  content: "􏙞";
}

.fad.fa-folder-times:after {
  content: "􏙟";
}

.fad.fa-folder-tree:after {
  content: "􏠂";
}

.fad.fa-folder-upload:after {
  content: "􎁔";
}

.fad.fa-folders:after {
  content: "􏙠";
}

.fad.fa-font:after {
  content: "􏀱";
}

.fad.fa-font-awesome-logo-full:after {
  content: "􏓦";
}

.fad.fa-font-case:after {
  content: "􏡦";
}

.fad.fa-football-ball:after {
  content: "􏑎";
}

.fad.fa-football-helmet:after {
  content: "􏑏";
}

.fad.fa-forklift:after {
  content: "􏑺";
}

.fad.fa-forward:after {
  content: "􏁎";
}

.fad.fa-fragile:after {
  content: "􏒻";
}

.fad.fa-french-fries:after {
  content: "􏠃";
}

.fad.fa-frog:after {
  content: "􏔮";
}

.fad.fa-frosty-head:after {
  content: "􏞛";
}

.fad.fa-frown:after {
  content: "􏄙";
}

.fad.fa-frown-open:after {
  content: "􏕺";
}

.fad.fa-function:after {
  content: "􏙡";
}

.fad.fa-funnel-dollar:after {
  content: "􏙢";
}

.fad.fa-futbol:after {
  content: "􏇣";
}

.fad.fa-galaxy:after {
  content: "􎀈";
}

.fad.fa-game-board:after {
  content: "􏡧";
}

.fad.fa-game-board-alt:after {
  content: "􏡨";
}

.fad.fa-game-console-handheld:after {
  content: "􏢻";
}

.fad.fa-gamepad:after {
  content: "􏄛";
}

.fad.fa-gamepad-alt:after {
  content: "􏢼";
}

.fad.fa-garage:after {
  content: "􎀉";
}

.fad.fa-garage-car:after {
  content: "􎀊";
}

.fad.fa-garage-open:after {
  content: "􎀋";
}

.fad.fa-gas-pump:after {
  content: "􏔯";
}

.fad.fa-gas-pump-slash:after {
  content: "􏗴";
}

.fad.fa-gavel:after {
  content: "􏃣";
}

.fad.fa-gem:after {
  content: "􏎥";
}

.fad.fa-genderless:after {
  content: "􏈭";
}

.fad.fa-ghost:after {
  content: "􏛢";
}

.fad.fa-gift:after {
  content: "􏁫";
}

.fad.fa-gift-card:after {
  content: "􏙣";
}

.fad.fa-gifts:after {
  content: "􏞜";
}

.fad.fa-gingerbread-man:after {
  content: "􏞝";
}

.fad.fa-glass:after {
  content: "􏠄";
}

.fad.fa-glass-champagne:after {
  content: "􏞞";
}

.fad.fa-glass-cheers:after {
  content: "􏞟";
}

.fad.fa-glass-citrus:after {
  content: "􏡩";
}

.fad.fa-glass-martini:after {
  content: "􏀀";
}

.fad.fa-glass-martini-alt:after {
  content: "􏕻";
}

.fad.fa-glass-whiskey:after {
  content: "􏞠";
}

.fad.fa-glass-whiskey-rocks:after {
  content: "􏞡";
}

.fad.fa-glasses:after {
  content: "􏔰";
}

.fad.fa-glasses-alt:after {
  content: "􏗵";
}

.fad.fa-globe:after {
  content: "􏂬";
}

.fad.fa-globe-africa:after {
  content: "􏕼";
}

.fad.fa-globe-americas:after {
  content: "􏕽";
}

.fad.fa-globe-asia:after {
  content: "􏕾";
}

.fad.fa-globe-europe:after {
  content: "􏞢";
}

.fad.fa-globe-snow:after {
  content: "􏞣";
}

.fad.fa-globe-stand:after {
  content: "􏗶";
}

.fad.fa-golf-ball:after {
  content: "􏑐";
}

.fad.fa-golf-club:after {
  content: "􏑑";
}

.fad.fa-gopuram:after {
  content: "􏙤";
}

.fad.fa-graduation-cap:after {
  content: "􏆝";
}

.fad.fa-gramophone:after {
  content: "􏢽";
}

.fad.fa-greater-than:after {
  content: "􏔱";
}

.fad.fa-greater-than-equal:after {
  content: "􏔲";
}

.fad.fa-grimace:after {
  content: "􏕿";
}

.fad.fa-grin:after {
  content: "􏖀";
}

.fad.fa-grin-alt:after {
  content: "􏖁";
}

.fad.fa-grin-beam:after {
  content: "􏖂";
}

.fad.fa-grin-beam-sweat:after {
  content: "􏖃";
}

.fad.fa-grin-hearts:after {
  content: "􏖄";
}

.fad.fa-grin-squint:after {
  content: "􏖅";
}

.fad.fa-grin-squint-tears:after {
  content: "􏖆";
}

.fad.fa-grin-stars:after {
  content: "􏖇";
}

.fad.fa-grin-tears:after {
  content: "􏖈";
}

.fad.fa-grin-tongue:after {
  content: "􏖉";
}

.fad.fa-grin-tongue-squint:after {
  content: "􏖊";
}

.fad.fa-grin-tongue-wink:after {
  content: "􏖋";
}

.fad.fa-grin-wink:after {
  content: "􏖌";
}

.fad.fa-grip-horizontal:after {
  content: "􏖍";
}

.fad.fa-grip-lines:after {
  content: "􏞤";
}

.fad.fa-grip-lines-vertical:after {
  content: "􏞥";
}

.fad.fa-grip-vertical:after {
  content: "􏖎";
}

.fad.fa-guitar:after {
  content: "􏞦";
}

.fad.fa-guitar-electric:after {
  content: "􏢾";
}

.fad.fa-guitars:after {
  content: "􏢿";
}

.fad.fa-h-square:after {
  content: "􏃽";
}

.fad.fa-h1:after {
  content: "􏌓";
}

.fad.fa-h2:after {
  content: "􏌔";
}

.fad.fa-h3:after {
  content: "􏌕";
}

.fad.fa-h4:after {
  content: "􏡪";
}

.fad.fa-hamburger:after {
  content: "􏠅";
}

.fad.fa-hammer:after {
  content: "􏛣";
}

.fad.fa-hammer-war:after {
  content: "􏛤";
}

.fad.fa-hamsa:after {
  content: "􏙥";
}

.fad.fa-hand-heart:after {
  content: "􏒼";
}

.fad.fa-hand-holding:after {
  content: "􏒽";
}

.fad.fa-hand-holding-box:after {
  content: "􏑻";
}

.fad.fa-hand-holding-heart:after {
  content: "􏒾";
}

.fad.fa-hand-holding-magic:after {
  content: "􏛥";
}

.fad.fa-hand-holding-medical:after {
  content: "􎁜";
}

.fad.fa-hand-holding-seedling:after {
  content: "􏒿";
}

.fad.fa-hand-holding-usd:after {
  content: "􏓀";
}

.fad.fa-hand-holding-water:after {
  content: "􏓁";
}

.fad.fa-hand-lizard:after {
  content: "􏉘";
}

.fad.fa-hand-middle-finger:after {
  content: "􏠆";
}

.fad.fa-hand-paper:after {
  content: "􏉖";
}

.fad.fa-hand-peace:after {
  content: "􏉛";
}

.fad.fa-hand-point-down:after {
  content: "􏂧";
}

.fad.fa-hand-point-left:after {
  content: "􏂥";
}

.fad.fa-hand-point-right:after {
  content: "􏂤";
}

.fad.fa-hand-point-up:after {
  content: "􏂦";
}

.fad.fa-hand-pointer:after {
  content: "􏉚";
}

.fad.fa-hand-receiving:after {
  content: "􏑼";
}

.fad.fa-hand-rock:after {
  content: "􏉕";
}

.fad.fa-hand-scissors:after {
  content: "􏉗";
}

.fad.fa-hand-sparkles:after {
  content: "􎁝";
}

.fad.fa-hand-spock:after {
  content: "􏉙";
}

.fad.fa-hands:after {
  content: "􏓂";
}

.fad.fa-hands-heart:after {
  content: "􏓃";
}

.fad.fa-hands-helping:after {
  content: "􏓄";
}

.fad.fa-hands-usd:after {
  content: "􏓅";
}

.fad.fa-hands-wash:after {
  content: "􎁞";
}

.fad.fa-handshake:after {
  content: "􏊵";
}

.fad.fa-handshake-alt:after {
  content: "􏓆";
}

.fad.fa-handshake-alt-slash:after {
  content: "􎁟";
}

.fad.fa-handshake-slash:after {
  content: "􎁠";
}

.fad.fa-hanukiah:after {
  content: "􏛦";
}

.fad.fa-hard-hat:after {
  content: "􏠇";
}

.fad.fa-hashtag:after {
  content: "􏊒";
}

.fad.fa-hat-chef:after {
  content: "􏡫";
}

.fad.fa-hat-cowboy:after {
  content: "􏣀";
}

.fad.fa-hat-cowboy-side:after {
  content: "􏣁";
}

.fad.fa-hat-santa:after {
  content: "􏞧";
}

.fad.fa-hat-winter:after {
  content: "􏞨";
}

.fad.fa-hat-witch:after {
  content: "􏛧";
}

.fad.fa-hat-wizard:after {
  content: "􏛨";
}

.fad.fa-hdd:after {
  content: "􏂠";
}

.fad.fa-head-side:after {
  content: "􏛩";
}

.fad.fa-head-side-brain:after {
  content: "􏠈";
}

.fad.fa-head-side-cough:after {
  content: "􎁡";
}

.fad.fa-head-side-cough-slash:after {
  content: "􎁢";
}

.fad.fa-head-side-headphones:after {
  content: "􏣂";
}

.fad.fa-head-side-mask:after {
  content: "􎁣";
}

.fad.fa-head-side-medical:after {
  content: "􏠉";
}

.fad.fa-head-side-virus:after {
  content: "􎁤";
}

.fad.fa-head-vr:after {
  content: "􏛪";
}

.fad.fa-heading:after {
  content: "􏇜";
}

.fad.fa-headphones:after {
  content: "􏀥";
}

.fad.fa-headphones-alt:after {
  content: "􏖏";
}

.fad.fa-headset:after {
  content: "􏖐";
}

.fad.fa-heart:after {
  content: "􏀄";
}

.fad.fa-heart-broken:after {
  content: "􏞩";
}

.fad.fa-heart-circle:after {
  content: "􏓇";
}

.fad.fa-heart-rate:after {
  content: "􏗸";
}

.fad.fa-heart-square:after {
  content: "􏓈";
}

.fad.fa-heartbeat:after {
  content: "􏈞";
}

.fad.fa-heat:after {
  content: "􎀌";
}

.fad.fa-helicopter:after {
  content: "􏔳";
}

.fad.fa-helmet-battle:after {
  content: "􏛫";
}

.fad.fa-hexagon:after {
  content: "􏌒";
}

.fad.fa-highlighter:after {
  content: "􏖑";
}

.fad.fa-hiking:after {
  content: "􏛬";
}

.fad.fa-hippo:after {
  content: "􏛭";
}

.fad.fa-history:after {
  content: "􏇚";
}

.fad.fa-hockey-mask:after {
  content: "􏛮";
}

.fad.fa-hockey-puck:after {
  content: "􏑓";
}

.fad.fa-hockey-sticks:after {
  content: "􏑔";
}

.fad.fa-holly-berry:after {
  content: "􏞪";
}

.fad.fa-home:after {
  content: "􏀕";
}

.fad.fa-home-alt:after {
  content: "􏠊";
}

.fad.fa-home-heart:after {
  content: "􏓉";
}

.fad.fa-home-lg:after {
  content: "􏠋";
}

.fad.fa-home-lg-alt:after {
  content: "􏠌";
}

.fad.fa-hood-cloak:after {
  content: "􏛯";
}

.fad.fa-horizontal-rule:after {
  content: "􏡬";
}

.fad.fa-horse:after {
  content: "􏛰";
}

.fad.fa-horse-head:after {
  content: "􏞫";
}

.fad.fa-horse-saddle:after {
  content: "􏣃";
}

.fad.fa-hospital:after {
  content: "􏃸";
}

.fad.fa-hospital-alt:after {
  content: "􏑽";
}

.fad.fa-hospital-symbol:after {
  content: "􏑾";
}

.fad.fa-hospital-user:after {
  content: "􏠍";
}

.fad.fa-hospitals:after {
  content: "􏠎";
}

.fad.fa-hot-tub:after {
  content: "􏖓";
}

.fad.fa-hotdog:after {
  content: "􏠏";
}

.fad.fa-hotel:after {
  content: "􏖔";
}

.fad.fa-hourglass:after {
  content: "􏉔";
}

.fad.fa-hourglass-end:after {
  content: "􏉓";
}

.fad.fa-hourglass-half:after {
  content: "􏉒";
}

.fad.fa-hourglass-start:after {
  content: "􏉑";
}

.fad.fa-house:after {
  content: "􎀍";
}

.fad.fa-house-damage:after {
  content: "􏛱";
}

.fad.fa-house-day:after {
  content: "􎀎";
}

.fad.fa-house-flood:after {
  content: "􏝏";
}

.fad.fa-house-leave:after {
  content: "􎀏";
}

.fad.fa-house-night:after {
  content: "􎀐";
}

.fad.fa-house-return:after {
  content: "􎀑";
}

.fad.fa-house-signal:after {
  content: "􎀒";
}

.fad.fa-house-user:after {
  content: "􎁥";
}

.fad.fa-hryvnia:after {
  content: "􏛲";
}

.fad.fa-humidity:after {
  content: "􏝐";
}

.fad.fa-hurricane:after {
  content: "􏝑";
}

.fad.fa-i-cursor:after {
  content: "􏉆";
}

.fad.fa-ice-cream:after {
  content: "􏠐";
}

.fad.fa-ice-skate:after {
  content: "􏞬";
}

.fad.fa-icicles:after {
  content: "􏞭";
}

.fad.fa-icons:after {
  content: "􏡭";
}

.fad.fa-icons-alt:after {
  content: "􏡮";
}

.fad.fa-id-badge:after {
  content: "􏋁";
}

.fad.fa-id-card:after {
  content: "􏋂";
}

.fad.fa-id-card-alt:after {
  content: "􏑿";
}

.fad.fa-igloo:after {
  content: "􏞮";
}

.fad.fa-image:after {
  content: "􏀾";
}

.fad.fa-image-polaroid:after {
  content: "􏣄";
}

.fad.fa-images:after {
  content: "􏌂";
}

.fad.fa-inbox:after {
  content: "􏀜";
}

.fad.fa-inbox-in:after {
  content: "􏌐";
}

.fad.fa-inbox-out:after {
  content: "􏌑";
}

.fad.fa-indent:after {
  content: "􏀼";
}

.fad.fa-industry:after {
  content: "􏉵";
}

.fad.fa-industry-alt:after {
  content: "􏎳";
}

.fad.fa-infinity:after {
  content: "􏔴";
}

.fad.fa-info:after {
  content: "􏄩";
}

.fad.fa-info-circle:after {
  content: "􏁚";
}

.fad.fa-info-square:after {
  content: "􏌏";
}

.fad.fa-inhaler:after {
  content: "􏗹";
}

.fad.fa-integral:after {
  content: "􏙧";
}

.fad.fa-intersection:after {
  content: "􏙨";
}

.fad.fa-inventory:after {
  content: "􏒀";
}

.fad.fa-island-tropical:after {
  content: "􏠑";
}

.fad.fa-italic:after {
  content: "􏀳";
}

.fad.fa-jack-o-lantern:after {
  content: "􏌎";
}

.fad.fa-jedi:after {
  content: "􏙩";
}

.fad.fa-joint:after {
  content: "􏖕";
}

.fad.fa-journal-whills:after {
  content: "􏙪";
}

.fad.fa-joystick:after {
  content: "􏣅";
}

.fad.fa-jug:after {
  content: "􏣆";
}

.fad.fa-kaaba:after {
  content: "􏙫";
}

.fad.fa-kazoo:after {
  content: "􏣇";
}

.fad.fa-kerning:after {
  content: "􏡯";
}

.fad.fa-key:after {
  content: "􏂄";
}

.fad.fa-key-skeleton:after {
  content: "􏛳";
}

.fad.fa-keyboard:after {
  content: "􏄜";
}

.fad.fa-keynote:after {
  content: "􏙬";
}

.fad.fa-khanda:after {
  content: "􏙭";
}

.fad.fa-kidneys:after {
  content: "􏗻";
}

.fad.fa-kiss:after {
  content: "􏖖";
}

.fad.fa-kiss-beam:after {
  content: "􏖗";
}

.fad.fa-kiss-wink-heart:after {
  content: "􏖘";
}

.fad.fa-kite:after {
  content: "􏛴";
}

.fad.fa-kiwi-bird:after {
  content: "􏔵";
}

.fad.fa-knife-kitchen:after {
  content: "􏛵";
}

.fad.fa-lambda:after {
  content: "􏙮";
}

.fad.fa-lamp:after {
  content: "􏓊";
}

.fad.fa-lamp-desk:after {
  content: "􎀔";
}

.fad.fa-lamp-floor:after {
  content: "􎀕";
}

.fad.fa-landmark:after {
  content: "􏙯";
}

.fad.fa-landmark-alt:after {
  content: "􏝒";
}

.fad.fa-language:after {
  content: "􏆫";
}

.fad.fa-laptop:after {
  content: "􏄉";
}

.fad.fa-laptop-code:after {
  content: "􏗼";
}

.fad.fa-laptop-house:after {
  content: "􎁦";
}

.fad.fa-laptop-medical:after {
  content: "􏠒";
}

.fad.fa-lasso:after {
  content: "􏣈";
}

.fad.fa-laugh:after {
  content: "􏖙";
}

.fad.fa-laugh-beam:after {
  content: "􏖚";
}

.fad.fa-laugh-squint:after {
  content: "􏖛";
}

.fad.fa-laugh-wink:after {
  content: "􏖜";
}

.fad.fa-layer-group:after {
  content: "􏗽";
}

.fad.fa-layer-minus:after {
  content: "􏗾";
}

.fad.fa-layer-plus:after {
  content: "􏗿";
}

.fad.fa-leaf:after {
  content: "􏁬";
}

.fad.fa-leaf-heart:after {
  content: "􏓋";
}

.fad.fa-leaf-maple:after {
  content: "􏛶";
}

.fad.fa-leaf-oak:after {
  content: "􏛷";
}

.fad.fa-lemon:after {
  content: "􏂔";
}

.fad.fa-less-than:after {
  content: "􏔶";
}

.fad.fa-less-than-equal:after {
  content: "􏔷";
}

.fad.fa-level-down:after {
  content: "􏅉";
}

.fad.fa-level-down-alt:after {
  content: "􏎾";
}

.fad.fa-level-up:after {
  content: "􏅈";
}

.fad.fa-level-up-alt:after {
  content: "􏎿";
}

.fad.fa-life-ring:after {
  content: "􏇍";
}

.fad.fa-light-ceiling:after {
  content: "􎀖";
}

.fad.fa-light-switch:after {
  content: "􎀗";
}

.fad.fa-light-switch-off:after {
  content: "􎀘";
}

.fad.fa-light-switch-on:after {
  content: "􎀙";
}

.fad.fa-lightbulb:after {
  content: "􏃫";
}

.fad.fa-lightbulb-dollar:after {
  content: "􏙰";
}

.fad.fa-lightbulb-exclamation:after {
  content: "􏙱";
}

.fad.fa-lightbulb-on:after {
  content: "􏙲";
}

.fad.fa-lightbulb-slash:after {
  content: "􏙳";
}

.fad.fa-lights-holiday:after {
  content: "􏞲";
}

.fad.fa-line-columns:after {
  content: "􏡰";
}

.fad.fa-line-height:after {
  content: "􏡱";
}

.fad.fa-link:after {
  content: "􏃁";
}

.fad.fa-lips:after {
  content: "􏘀";
}

.fad.fa-lira-sign:after {
  content: "􏆕";
}

.fad.fa-list:after {
  content: "􏀺";
}

.fad.fa-list-alt:after {
  content: "􏀢";
}

.fad.fa-list-music:after {
  content: "􏣉";
}

.fad.fa-list-ol:after {
  content: "􏃋";
}

.fad.fa-list-ul:after {
  content: "􏃊";
}

.fad.fa-location:after {
  content: "􏘁";
}

.fad.fa-location-arrow:after {
  content: "􏄤";
}

.fad.fa-location-circle:after {
  content: "􏘂";
}

.fad.fa-location-slash:after {
  content: "􏘃";
}

.fad.fa-lock:after {
  content: "􏀣";
}

.fad.fa-lock-alt:after {
  content: "􏌍";
}

.fad.fa-lock-open:after {
  content: "􏏁";
}

.fad.fa-lock-open-alt:after {
  content: "􏏂";
}

.fad.fa-long-arrow-alt-down:after {
  content: "􏌉";
}

.fad.fa-long-arrow-alt-left:after {
  content: "􏌊";
}

.fad.fa-long-arrow-alt-right:after {
  content: "􏌋";
}

.fad.fa-long-arrow-alt-up:after {
  content: "􏌌";
}

.fad.fa-long-arrow-down:after {
  content: "􏅵";
}

.fad.fa-long-arrow-left:after {
  content: "􏅷";
}

.fad.fa-long-arrow-right:after {
  content: "􏅸";
}

.fad.fa-long-arrow-up:after {
  content: "􏅶";
}

.fad.fa-loveseat:after {
  content: "􏓌";
}

.fad.fa-low-vision:after {
  content: "􏊨";
}

.fad.fa-luchador:after {
  content: "􏑕";
}

.fad.fa-luggage-cart:after {
  content: "􏖝";
}

.fad.fa-lungs:after {
  content: "􏘄";
}

.fad.fa-lungs-virus:after {
  content: "􎁧";
}

.fad.fa-mace:after {
  content: "􏛸";
}

.fad.fa-magic:after {
  content: "􏃐";
}

.fad.fa-magnet:after {
  content: "􏁶";
}

.fad.fa-mail-bulk:after {
  content: "􏙴";
}

.fad.fa-mailbox:after {
  content: "􏠓";
}

.fad.fa-male:after {
  content: "􏆃";
}

.fad.fa-mandolin:after {
  content: "􏛹";
}

.fad.fa-map:after {
  content: "􏉹";
}

.fad.fa-map-marked:after {
  content: "􏖟";
}

.fad.fa-map-marked-alt:after {
  content: "􏖠";
}

.fad.fa-map-marker:after {
  content: "􏁁";
}

.fad.fa-map-marker-alt:after {
  content: "􏏅";
}

.fad.fa-map-marker-alt-slash:after {
  content: "􏘅";
}

.fad.fa-map-marker-check:after {
  content: "􏘆";
}

.fad.fa-map-marker-edit:after {
  content: "􏘇";
}

.fad.fa-map-marker-exclamation:after {
  content: "􏘈";
}

.fad.fa-map-marker-minus:after {
  content: "􏘉";
}

.fad.fa-map-marker-plus:after {
  content: "􏘊";
}

.fad.fa-map-marker-question:after {
  content: "􏘋";
}

.fad.fa-map-marker-slash:after {
  content: "􏘌";
}

.fad.fa-map-marker-smile:after {
  content: "􏘍";
}

.fad.fa-map-marker-times:after {
  content: "􏘎";
}

.fad.fa-map-pin:after {
  content: "􏉶";
}

.fad.fa-map-signs:after {
  content: "􏉷";
}

.fad.fa-marker:after {
  content: "􏖡";
}

.fad.fa-mars:after {
  content: "􏈢";
}

.fad.fa-mars-double:after {
  content: "􏈧";
}

.fad.fa-mars-stroke:after {
  content: "􏈩";
}

.fad.fa-mars-stroke-h:after {
  content: "􏈫";
}

.fad.fa-mars-stroke-v:after {
  content: "􏈪";
}

.fad.fa-mask:after {
  content: "􏛺";
}

.fad.fa-meat:after {
  content: "􏠔";
}

.fad.fa-medal:after {
  content: "􏖢";
}

.fad.fa-medkit:after {
  content: "􏃺";
}

.fad.fa-megaphone:after {
  content: "􏙵";
}

.fad.fa-meh:after {
  content: "􏄚";
}

.fad.fa-meh-blank:after {
  content: "􏖤";
}

.fad.fa-meh-rolling-eyes:after {
  content: "􏖥";
}

.fad.fa-memory:after {
  content: "􏔸";
}

.fad.fa-menorah:after {
  content: "􏙶";
}

.fad.fa-mercury:after {
  content: "􏈣";
}

.fad.fa-meteor:after {
  content: "􏝓";
}

.fad.fa-microchip:after {
  content: "􏋛";
}

.fad.fa-microphone:after {
  content: "􏄰";
}

.fad.fa-microphone-alt:after {
  content: "􏏉";
}

.fad.fa-microphone-alt-slash:after {
  content: "􏔹";
}

.fad.fa-microphone-slash:after {
  content: "􏄱";
}

.fad.fa-microphone-stand:after {
  content: "􏣋";
}

.fad.fa-microscope:after {
  content: "􏘐";
}

.fad.fa-microwave:after {
  content: "􎀛";
}

.fad.fa-mind-share:after {
  content: "􏙷";
}

.fad.fa-minus:after {
  content: "􏁨";
}

.fad.fa-minus-circle:after {
  content: "􏁖";
}

.fad.fa-minus-hexagon:after {
  content: "􏌇";
}

.fad.fa-minus-octagon:after {
  content: "􏌈";
}

.fad.fa-minus-square:after {
  content: "􏅆";
}

.fad.fa-mistletoe:after {
  content: "􏞴";
}

.fad.fa-mitten:after {
  content: "􏞵";
}

.fad.fa-mobile:after {
  content: "􏄋";
}

.fad.fa-mobile-alt:after {
  content: "􏏍";
}

.fad.fa-mobile-android:after {
  content: "􏏎";
}

.fad.fa-mobile-android-alt:after {
  content: "􏏏";
}

.fad.fa-money-bill:after {
  content: "􏃖";
}

.fad.fa-money-bill-alt:after {
  content: "􏏑";
}

.fad.fa-money-bill-wave:after {
  content: "􏔺";
}

.fad.fa-money-bill-wave-alt:after {
  content: "􏔻";
}

.fad.fa-money-check:after {
  content: "􏔼";
}

.fad.fa-money-check-alt:after {
  content: "􏔽";
}

.fad.fa-money-check-edit:after {
  content: "􏡲";
}

.fad.fa-money-check-edit-alt:after {
  content: "􏡳";
}

.fad.fa-monitor-heart-rate:after {
  content: "􏘑";
}

.fad.fa-monkey:after {
  content: "􏛻";
}

.fad.fa-monument:after {
  content: "􏖦";
}

.fad.fa-moon:after {
  content: "􏆆";
}

.fad.fa-moon-cloud:after {
  content: "􏝔";
}

.fad.fa-moon-stars:after {
  content: "􏝕";
}

.fad.fa-mortar-pestle:after {
  content: "􏖧";
}

.fad.fa-mosque:after {
  content: "􏙸";
}

.fad.fa-motorcycle:after {
  content: "􏈜";
}

.fad.fa-mountain:after {
  content: "􏛼";
}

.fad.fa-mountains:after {
  content: "􏛽";
}

.fad.fa-mouse:after {
  content: "􏣌";
}

.fad.fa-mouse-alt:after {
  content: "􏣍";
}

.fad.fa-mouse-pointer:after {
  content: "􏉅";
}

.fad.fa-mp3-player:after {
  content: "􏣎";
}

.fad.fa-mug:after {
  content: "􏡴";
}

.fad.fa-mug-hot:after {
  content: "􏞶";
}

.fad.fa-mug-marshmallows:after {
  content: "􏞷";
}

.fad.fa-mug-tea:after {
  content: "􏡵";
}

.fad.fa-music:after {
  content: "􏀁";
}

.fad.fa-music-alt:after {
  content: "􏣏";
}

.fad.fa-music-alt-slash:after {
  content: "􏣐";
}

.fad.fa-music-slash:after {
  content: "􏣑";
}

.fad.fa-narwhal:after {
  content: "􏛾";
}

.fad.fa-network-wired:after {
  content: "􏛿";
}

.fad.fa-neuter:after {
  content: "􏈬";
}

.fad.fa-newspaper:after {
  content: "􏇪";
}

.fad.fa-not-equal:after {
  content: "􏔾";
}

.fad.fa-notes-medical:after {
  content: "􏒁";
}

.fad.fa-object-group:after {
  content: "􏉇";
}

.fad.fa-object-ungroup:after {
  content: "􏉈";
}

.fad.fa-octagon:after {
  content: "􏌆";
}

.fad.fa-oil-can:after {
  content: "􏘓";
}

.fad.fa-oil-temp:after {
  content: "􏘔";
}

.fad.fa-om:after {
  content: "􏙹";
}

.fad.fa-omega:after {
  content: "􏙺";
}

.fad.fa-ornament:after {
  content: "􏞸";
}

.fad.fa-otter:after {
  content: "􏜀";
}

.fad.fa-outdent:after {
  content: "􏀻";
}

.fad.fa-outlet:after {
  content: "􎀜";
}

.fad.fa-oven:after {
  content: "􎀝";
}

.fad.fa-overline:after {
  content: "􏡶";
}

.fad.fa-page-break:after {
  content: "􏡷";
}

.fad.fa-pager:after {
  content: "􏠕";
}

.fad.fa-paint-brush:after {
  content: "􏇼";
}

.fad.fa-paint-brush-alt:after {
  content: "􏖩";
}

.fad.fa-paint-roller:after {
  content: "􏖪";
}

.fad.fa-palette:after {
  content: "􏔿";
}

.fad.fa-pallet:after {
  content: "􏒂";
}

.fad.fa-pallet-alt:after {
  content: "􏒃";
}

.fad.fa-paper-plane:after {
  content: "􏇘";
}

.fad.fa-paperclip:after {
  content: "􏃆";
}

.fad.fa-parachute-box:after {
  content: "􏓍";
}

.fad.fa-paragraph:after {
  content: "􏇝";
}

.fad.fa-paragraph-rtl:after {
  content: "􏡸";
}

.fad.fa-parking:after {
  content: "􏕀";
}

.fad.fa-parking-circle:after {
  content: "􏘕";
}

.fad.fa-parking-circle-slash:after {
  content: "􏘖";
}

.fad.fa-parking-slash:after {
  content: "􏘗";
}

.fad.fa-passport:after {
  content: "􏖫";
}

.fad.fa-pastafarianism:after {
  content: "􏙻";
}

.fad.fa-paste:after {
  content: "􏃪";
}

.fad.fa-pause:after {
  content: "􏁌";
}

.fad.fa-pause-circle:after {
  content: "􏊋";
}

.fad.fa-paw:after {
  content: "􏆰";
}

.fad.fa-paw-alt:after {
  content: "􏜁";
}

.fad.fa-paw-claws:after {
  content: "􏜂";
}

.fad.fa-peace:after {
  content: "􏙼";
}

.fad.fa-pegasus:after {
  content: "􏜃";
}

.fad.fa-pen:after {
  content: "􏌄";
}

.fad.fa-pen-alt:after {
  content: "􏌅";
}

.fad.fa-pen-fancy:after {
  content: "􏖬";
}

.fad.fa-pen-nib:after {
  content: "􏖭";
}

.fad.fa-pen-square:after {
  content: "􏅋";
}

.fad.fa-pencil:after {
  content: "􏁀";
}

.fad.fa-pencil-alt:after {
  content: "􏌃";
}

.fad.fa-pencil-paintbrush:after {
  content: "􏘘";
}

.fad.fa-pencil-ruler:after {
  content: "􏖮";
}

.fad.fa-pennant:after {
  content: "􏑖";
}

.fad.fa-people-arrows:after {
  content: "􎁨";
}

.fad.fa-people-carry:after {
  content: "􏓎";
}

.fad.fa-pepper-hot:after {
  content: "􏠖";
}

.fad.fa-percent:after {
  content: "􏊕";
}

.fad.fa-percentage:after {
  content: "􏕁";
}

.fad.fa-person-booth:after {
  content: "􏝖";
}

.fad.fa-person-carry:after {
  content: "􏓏";
}

.fad.fa-person-dolly:after {
  content: "􏓐";
}

.fad.fa-person-dolly-empty:after {
  content: "􏓑";
}

.fad.fa-person-sign:after {
  content: "􏝗";
}

.fad.fa-phone:after {
  content: "􏂕";
}

.fad.fa-phone-alt:after {
  content: "􏡹";
}

.fad.fa-phone-laptop:after {
  content: "􏡺";
}

.fad.fa-phone-office:after {
  content: "􏙽";
}

.fad.fa-phone-plus:after {
  content: "􏓒";
}

.fad.fa-phone-rotary:after {
  content: "􏣓";
}

.fad.fa-phone-slash:after {
  content: "􏏝";
}

.fad.fa-phone-square:after {
  content: "􏂘";
}

.fad.fa-phone-square-alt:after {
  content: "􏡻";
}

.fad.fa-phone-volume:after {
  content: "􏊠";
}

.fad.fa-photo-video:after {
  content: "􏡼";
}

.fad.fa-pi:after {
  content: "􏙾";
}

.fad.fa-piano:after {
  content: "􏣔";
}

.fad.fa-piano-keyboard:after {
  content: "􏣕";
}

.fad.fa-pie:after {
  content: "􏜅";
}

.fad.fa-pig:after {
  content: "􏜆";
}

.fad.fa-piggy-bank:after {
  content: "􏓓";
}

.fad.fa-pills:after {
  content: "􏒄";
}

.fad.fa-pizza:after {
  content: "􏠗";
}

.fad.fa-pizza-slice:after {
  content: "􏠘";
}

.fad.fa-place-of-worship:after {
  content: "􏙿";
}

.fad.fa-plane:after {
  content: "􏁲";
}

.fad.fa-plane-alt:after {
  content: "􏏞";
}

.fad.fa-plane-arrival:after {
  content: "􏖯";
}

.fad.fa-plane-departure:after {
  content: "􏖰";
}

.fad.fa-plane-slash:after {
  content: "􎁩";
}

.fad.fa-planet-moon:after {
  content: "􎀟";
}

.fad.fa-planet-ringed:after {
  content: "􎀠";
}

.fad.fa-play:after {
  content: "􏁋";
}

.fad.fa-play-circle:after {
  content: "􏅄";
}

.fad.fa-plug:after {
  content: "􏇦";
}

.fad.fa-plus:after {
  content: "􏁧";
}

.fad.fa-plus-circle:after {
  content: "􏁕";
}

.fad.fa-plus-hexagon:after {
  content: "􏌀";
}

.fad.fa-plus-octagon:after {
  content: "􏌁";
}

.fad.fa-plus-square:after {
  content: "􏃾";
}

.fad.fa-podcast:after {
  content: "􏋎";
}

.fad.fa-podium:after {
  content: "􏚀";
}

.fad.fa-podium-star:after {
  content: "􏝘";
}

.fad.fa-police-box:after {
  content: "􎀡";
}

.fad.fa-poll:after {
  content: "􏚁";
}

.fad.fa-poll-h:after {
  content: "􏚂";
}

.fad.fa-poll-people:after {
  content: "􏝙";
}

.fad.fa-poo:after {
  content: "􏋾";
}

.fad.fa-poo-storm:after {
  content: "􏝚";
}

.fad.fa-poop:after {
  content: "􏘙";
}

.fad.fa-popcorn:after {
  content: "􏠙";
}

.fad.fa-portal-enter:after {
  content: "􎀢";
}

.fad.fa-portal-exit:after {
  content: "􎀣";
}

.fad.fa-portrait:after {
  content: "􏏠";
}

.fad.fa-pound-sign:after {
  content: "􏅔";
}

.fad.fa-power-off:after {
  content: "􏀑";
}

.fad.fa-pray:after {
  content: "􏚃";
}

.fad.fa-praying-hands:after {
  content: "􏚄";
}

.fad.fa-prescription:after {
  content: "􏖱";
}

.fad.fa-prescription-bottle:after {
  content: "􏒅";
}

.fad.fa-prescription-bottle-alt:after {
  content: "􏒆";
}

.fad.fa-presentation:after {
  content: "􏚅";
}

.fad.fa-print:after {
  content: "􏀯";
}

.fad.fa-print-search:after {
  content: "􏠚";
}

.fad.fa-print-slash:after {
  content: "􏚆";
}

.fad.fa-procedures:after {
  content: "􏒇";
}

.fad.fa-project-diagram:after {
  content: "􏕂";
}

.fad.fa-projector:after {
  content: "􏣖";
}

.fad.fa-pump-medical:after {
  content: "􎁪";
}

.fad.fa-pump-soap:after {
  content: "􎁫";
}

.fad.fa-pumpkin:after {
  content: "􏜇";
}

.fad.fa-puzzle-piece:after {
  content: "􏄮";
}

.fad.fa-qrcode:after {
  content: "􏀩";
}

.fad.fa-question:after {
  content: "􏄨";
}

.fad.fa-question-circle:after {
  content: "􏁙";
}

.fad.fa-question-square:after {
  content: "􏋽";
}

.fad.fa-quidditch:after {
  content: "􏑘";
}

.fad.fa-quote-left:after {
  content: "􏄍";
}

.fad.fa-quote-right:after {
  content: "􏄎";
}

.fad.fa-quran:after {
  content: "􏚇";
}

.fad.fa-rabbit:after {
  content: "􏜈";
}

.fad.fa-rabbit-fast:after {
  content: "􏜉";
}

.fad.fa-racquet:after {
  content: "􏑚";
}

.fad.fa-radar:after {
  content: "􎀤";
}

.fad.fa-radiation:after {
  content: "􏞹";
}

.fad.fa-radiation-alt:after {
  content: "􏞺";
}

.fad.fa-radio:after {
  content: "􏣗";
}

.fad.fa-radio-alt:after {
  content: "􏣘";
}

.fad.fa-rainbow:after {
  content: "􏝛";
}

.fad.fa-raindrops:after {
  content: "􏝜";
}

.fad.fa-ram:after {
  content: "􏜊";
}

.fad.fa-ramp-loading:after {
  content: "􏓔";
}

.fad.fa-random:after {
  content: "􏁴";
}

.fad.fa-raygun:after {
  content: "􎀥";
}

.fad.fa-receipt:after {
  content: "􏕃";
}

.fad.fa-record-vinyl:after {
  content: "􏣙";
}

.fad.fa-rectangle-landscape:after {
  content: "􏋺";
}

.fad.fa-rectangle-portrait:after {
  content: "􏋻";
}

.fad.fa-rectangle-wide:after {
  content: "􏋼";
}

.fad.fa-recycle:after {
  content: "􏆸";
}

.fad.fa-redo:after {
  content: "􏀞";
}

.fad.fa-redo-alt:after {
  content: "􏋹";
}

.fad.fa-refrigerator:after {
  content: "􎀦";
}

.fad.fa-registered:after {
  content: "􏉝";
}

.fad.fa-remove-format:after {
  content: "􏡽";
}

.fad.fa-repeat:after {
  content: "􏍣";
}

.fad.fa-repeat-1:after {
  content: "􏍥";
}

.fad.fa-repeat-1-alt:after {
  content: "􏍦";
}

.fad.fa-repeat-alt:after {
  content: "􏍤";
}

.fad.fa-reply:after {
  content: "􏏥";
}

.fad.fa-reply-all:after {
  content: "􏄢";
}

.fad.fa-republican:after {
  content: "􏝞";
}

.fad.fa-restroom:after {
  content: "􏞽";
}

.fad.fa-retweet:after {
  content: "􏁹";
}

.fad.fa-retweet-alt:after {
  content: "􏍡";
}

.fad.fa-ribbon:after {
  content: "􏓖";
}

.fad.fa-ring:after {
  content: "􏜋";
}

.fad.fa-rings-wedding:after {
  content: "􏠛";
}

.fad.fa-road:after {
  content: "􏀘";
}

.fad.fa-robot:after {
  content: "􏕄";
}

.fad.fa-rocket:after {
  content: "􏄵";
}

.fad.fa-rocket-launch:after {
  content: "􎀧";
}

.fad.fa-route:after {
  content: "􏓗";
}

.fad.fa-route-highway:after {
  content: "􏘚";
}

.fad.fa-route-interstate:after {
  content: "􏘛";
}

.fad.fa-router:after {
  content: "􏣚";
}

.fad.fa-rss:after {
  content: "􏂞";
}

.fad.fa-rss-square:after {
  content: "􏅃";
}

.fad.fa-ruble-sign:after {
  content: "􏅘";
}

.fad.fa-ruler:after {
  content: "􏕅";
}

.fad.fa-ruler-combined:after {
  content: "􏕆";
}

.fad.fa-ruler-horizontal:after {
  content: "􏕇";
}

.fad.fa-ruler-triangle:after {
  content: "􏘜";
}

.fad.fa-ruler-vertical:after {
  content: "􏕈";
}

.fad.fa-running:after {
  content: "􏜌";
}

.fad.fa-rupee-sign:after {
  content: "􏅖";
}

.fad.fa-rv:after {
  content: "􏞾";
}

.fad.fa-sack:after {
  content: "􏠜";
}

.fad.fa-sack-dollar:after {
  content: "􏠝";
}

.fad.fa-sad-cry:after {
  content: "􏖳";
}

.fad.fa-sad-tear:after {
  content: "􏖴";
}

.fad.fa-salad:after {
  content: "􏠞";
}

.fad.fa-sandwich:after {
  content: "􏠟";
}

.fad.fa-satellite:after {
  content: "􏞿";
}

.fad.fa-satellite-dish:after {
  content: "􏟀";
}

.fad.fa-sausage:after {
  content: "􏠠";
}

.fad.fa-save:after {
  content: "􏃇";
}

.fad.fa-sax-hot:after {
  content: "􏣛";
}

.fad.fa-saxophone:after {
  content: "􏣜";
}

.fad.fa-scalpel:after {
  content: "􏘝";
}

.fad.fa-scalpel-path:after {
  content: "􏘞";
}

.fad.fa-scanner:after {
  content: "􏒈";
}

.fad.fa-scanner-image:after {
  content: "􏣳";
}

.fad.fa-scanner-keyboard:after {
  content: "􏒉";
}

.fad.fa-scanner-touchscreen:after {
  content: "􏒊";
}

.fad.fa-scarecrow:after {
  content: "􏜍";
}

.fad.fa-scarf:after {
  content: "􏟁";
}

.fad.fa-school:after {
  content: "􏕉";
}

.fad.fa-screwdriver:after {
  content: "􏕊";
}

.fad.fa-scroll:after {
  content: "􏜎";
}

.fad.fa-scroll-old:after {
  content: "􏜏";
}

.fad.fa-scrubber:after {
  content: "􏋸";
}

.fad.fa-scythe:after {
  content: "􏜐";
}

.fad.fa-sd-card:after {
  content: "􏟂";
}

.fad.fa-search:after {
  content: "􏀂";
}

.fad.fa-search-dollar:after {
  content: "􏚈";
}

.fad.fa-search-location:after {
  content: "􏚉";
}

.fad.fa-search-minus:after {
  content: "􏀐";
}

.fad.fa-search-plus:after {
  content: "􏀎";
}

.fad.fa-seedling:after {
  content: "􏓘";
}

.fad.fa-send-back:after {
  content: "􏡾";
}

.fad.fa-send-backward:after {
  content: "􏡿";
}

.fad.fa-sensor:after {
  content: "􎀨";
}

.fad.fa-sensor-alert:after {
  content: "􎀩";
}

.fad.fa-sensor-fire:after {
  content: "􎀪";
}

.fad.fa-sensor-on:after {
  content: "􎀫";
}

.fad.fa-sensor-smoke:after {
  content: "􎀬";
}

.fad.fa-server:after {
  content: "􏈳";
}

.fad.fa-shapes:after {
  content: "􏘟";
}

.fad.fa-share:after {
  content: "􏁤";
}

.fad.fa-share-all:after {
  content: "􏍧";
}

.fad.fa-share-alt:after {
  content: "􏇠";
}

.fad.fa-share-alt-square:after {
  content: "􏇡";
}

.fad.fa-share-square:after {
  content: "􏅍";
}

.fad.fa-sheep:after {
  content: "􏜑";
}

.fad.fa-shekel-sign:after {
  content: "􏈋";
}

.fad.fa-shield:after {
  content: "􏄲";
}

.fad.fa-shield-alt:after {
  content: "􏏭";
}

.fad.fa-shield-check:after {
  content: "􏋷";
}

.fad.fa-shield-cross:after {
  content: "􏜒";
}

.fad.fa-shield-virus:after {
  content: "􎁬";
}

.fad.fa-ship:after {
  content: "􏈚";
}

.fad.fa-shipping-fast:after {
  content: "􏒋";
}

.fad.fa-shipping-timed:after {
  content: "􏒌";
}

.fad.fa-shish-kebab:after {
  content: "􏠡";
}

.fad.fa-shoe-prints:after {
  content: "􏕋";
}

.fad.fa-shopping-bag:after {
  content: "􏊐";
}

.fad.fa-shopping-basket:after {
  content: "􏊑";
}

.fad.fa-shopping-cart:after {
  content: "􏁺";
}

.fad.fa-shovel:after {
  content: "􏜓";
}

.fad.fa-shovel-snow:after {
  content: "􏟃";
}

.fad.fa-shower:after {
  content: "􏋌";
}

.fad.fa-shredder:after {
  content: "􏚊";
}

.fad.fa-shuttle-van:after {
  content: "􏖶";
}

.fad.fa-shuttlecock:after {
  content: "􏑛";
}

.fad.fa-sickle:after {
  content: "􏠢";
}

.fad.fa-sigma:after {
  content: "􏚋";
}

.fad.fa-sign:after {
  content: "􏓙";
}

.fad.fa-sign-in:after {
  content: "􏂐";
}

.fad.fa-sign-in-alt:after {
  content: "􏋶";
}

.fad.fa-sign-language:after {
  content: "􏊧";
}

.fad.fa-sign-out:after {
  content: "􏂋";
}

.fad.fa-sign-out-alt:after {
  content: "􏋵";
}

.fad.fa-signal:after {
  content: "􏀒";
}

.fad.fa-signal-1:after {
  content: "􏚌";
}

.fad.fa-signal-2:after {
  content: "􏚍";
}

.fad.fa-signal-3:after {
  content: "􏚎";
}

.fad.fa-signal-4:after {
  content: "􏚏";
}

.fad.fa-signal-alt:after {
  content: "􏚐";
}

.fad.fa-signal-alt-1:after {
  content: "􏚑";
}

.fad.fa-signal-alt-2:after {
  content: "􏚒";
}

.fad.fa-signal-alt-3:after {
  content: "􏚓";
}

.fad.fa-signal-alt-slash:after {
  content: "􏚔";
}

.fad.fa-signal-slash:after {
  content: "􏚕";
}

.fad.fa-signal-stream:after {
  content: "􏣝";
}

.fad.fa-signature:after {
  content: "􏖷";
}

.fad.fa-sim-card:after {
  content: "􏟄";
}

.fad.fa-sink:after {
  content: "􎁭";
}

.fad.fa-siren:after {
  content: "􎀭";
}

.fad.fa-siren-on:after {
  content: "􎀮";
}

.fad.fa-sitemap:after {
  content: "􏃨";
}

.fad.fa-skating:after {
  content: "􏟅";
}

.fad.fa-skeleton:after {
  content: "􏘠";
}

.fad.fa-ski-jump:after {
  content: "􏟇";
}

.fad.fa-ski-lift:after {
  content: "􏟈";
}

.fad.fa-skiing:after {
  content: "􏟉";
}

.fad.fa-skiing-nordic:after {
  content: "􏟊";
}

.fad.fa-skull:after {
  content: "􏕌";
}

.fad.fa-skull-cow:after {
  content: "􏣞";
}

.fad.fa-skull-crossbones:after {
  content: "􏜔";
}

.fad.fa-slash:after {
  content: "􏜕";
}

.fad.fa-sledding:after {
  content: "􏟋";
}

.fad.fa-sleigh:after {
  content: "􏟌";
}

.fad.fa-sliders-h:after {
  content: "􏇞";
}

.fad.fa-sliders-h-square:after {
  content: "􏏰";
}

.fad.fa-sliders-v:after {
  content: "􏏱";
}

.fad.fa-sliders-v-square:after {
  content: "􏏲";
}

.fad.fa-smile:after {
  content: "􏄘";
}

.fad.fa-smile-beam:after {
  content: "􏖸";
}

.fad.fa-smile-plus:after {
  content: "􏖹";
}

.fad.fa-smile-wink:after {
  content: "􏓚";
}

.fad.fa-smog:after {
  content: "􏝟";
}

.fad.fa-smoke:after {
  content: "􏝠";
}

.fad.fa-smoking:after {
  content: "􏒍";
}

.fad.fa-smoking-ban:after {
  content: "􏕍";
}

.fad.fa-sms:after {
  content: "􏟍";
}

.fad.fa-snake:after {
  content: "􏜖";
}

.fad.fa-snooze:after {
  content: "􏢀";
}

.fad.fa-snow-blowing:after {
  content: "􏝡";
}

.fad.fa-snowboarding:after {
  content: "􏟎";
}

.fad.fa-snowflake:after {
  content: "􏋜";
}

.fad.fa-snowflakes:after {
  content: "􏟏";
}

.fad.fa-snowman:after {
  content: "􏟐";
}

.fad.fa-snowmobile:after {
  content: "􏟑";
}

.fad.fa-snowplow:after {
  content: "􏟒";
}

.fad.fa-soap:after {
  content: "􎁮";
}

.fad.fa-socks:after {
  content: "􏚖";
}

.fad.fa-solar-panel:after {
  content: "􏖺";
}

.fad.fa-solar-system:after {
  content: "􎀯";
}

.fad.fa-sort:after {
  content: "􏃜";
}

.fad.fa-sort-alpha-down:after {
  content: "􏅝";
}

.fad.fa-sort-alpha-down-alt:after {
  content: "􏢁";
}

.fad.fa-sort-alpha-up:after {
  content: "􏅞";
}

.fad.fa-sort-alpha-up-alt:after {
  content: "􏢂";
}

.fad.fa-sort-alt:after {
  content: "􏢃";
}

.fad.fa-sort-amount-down:after {
  content: "􏅠";
}

.fad.fa-sort-amount-down-alt:after {
  content: "􏢄";
}

.fad.fa-sort-amount-up:after {
  content: "􏅡";
}

.fad.fa-sort-amount-up-alt:after {
  content: "􏢅";
}

.fad.fa-sort-circle:after {
  content: "􎀰";
}

.fad.fa-sort-circle-down:after {
  content: "􎀱";
}

.fad.fa-sort-circle-up:after {
  content: "􎀲";
}

.fad.fa-sort-down:after {
  content: "􏃝";
}

.fad.fa-sort-numeric-down:after {
  content: "􏅢";
}

.fad.fa-sort-numeric-down-alt:after {
  content: "􏢆";
}

.fad.fa-sort-numeric-up:after {
  content: "􏅣";
}

.fad.fa-sort-numeric-up-alt:after {
  content: "􏢇";
}

.fad.fa-sort-shapes-down:after {
  content: "􏢈";
}

.fad.fa-sort-shapes-down-alt:after {
  content: "􏢉";
}

.fad.fa-sort-shapes-up:after {
  content: "􏢊";
}

.fad.fa-sort-shapes-up-alt:after {
  content: "􏢋";
}

.fad.fa-sort-size-down:after {
  content: "􏢌";
}

.fad.fa-sort-size-down-alt:after {
  content: "􏢍";
}

.fad.fa-sort-size-up:after {
  content: "􏢎";
}

.fad.fa-sort-size-up-alt:after {
  content: "􏢏";
}

.fad.fa-sort-up:after {
  content: "􏃞";
}

.fad.fa-soup:after {
  content: "􏠣";
}

.fad.fa-spa:after {
  content: "􏖻";
}

.fad.fa-space-shuttle:after {
  content: "􏆗";
}

.fad.fa-space-station-moon:after {
  content: "􎀳";
}

.fad.fa-space-station-moon-alt:after {
  content: "􎀴";
}

.fad.fa-spade:after {
  content: "􏋴";
}

.fad.fa-sparkles:after {
  content: "􏢐";
}

.fad.fa-speaker:after {
  content: "􏣟";
}

.fad.fa-speakers:after {
  content: "􏣠";
}

.fad.fa-spell-check:after {
  content: "􏢑";
}

.fad.fa-spider:after {
  content: "􏜗";
}

.fad.fa-spider-black-widow:after {
  content: "􏜘";
}

.fad.fa-spider-web:after {
  content: "􏜙";
}

.fad.fa-spinner:after {
  content: "􏄐";
}

.fad.fa-spinner-third:after {
  content: "􏏴";
}

.fad.fa-splotch:after {
  content: "􏖼";
}

.fad.fa-spray-can:after {
  content: "􏖽";
}

.fad.fa-sprinkler:after {
  content: "􎀵";
}

.fad.fa-square:after {
  content: "􏃈";
}

.fad.fa-square-full:after {
  content: "􏑜";
}

.fad.fa-square-root:after {
  content: "􏚗";
}

.fad.fa-square-root-alt:after {
  content: "􏚘";
}

.fad.fa-squirrel:after {
  content: "􏜚";
}

.fad.fa-staff:after {
  content: "􏜛";
}

.fad.fa-stamp:after {
  content: "􏖿";
}

.fad.fa-star:after {
  content: "􏀅";
}

.fad.fa-star-and-crescent:after {
  content: "􏚙";
}

.fad.fa-star-christmas:after {
  content: "􏟔";
}

.fad.fa-star-exclamation:after {
  content: "􏋳";
}

.fad.fa-star-half:after {
  content: "􏂉";
}

.fad.fa-star-half-alt:after {
  content: "􏗀";
}

.fad.fa-star-of-david:after {
  content: "􏚚";
}

.fad.fa-star-of-life:after {
  content: "􏘡";
}

.fad.fa-star-shooting:after {
  content: "􎀶";
}

.fad.fa-starfighter:after {
  content: "􎀷";
}

.fad.fa-starfighter-alt:after {
  content: "􎀸";
}

.fad.fa-stars:after {
  content: "􏝢";
}

.fad.fa-starship:after {
  content: "􎀹";
}

.fad.fa-starship-freighter:after {
  content: "􎀺";
}

.fad.fa-steak:after {
  content: "􏠤";
}

.fad.fa-steering-wheel:after {
  content: "􏘢";
}

.fad.fa-step-backward:after {
  content: "􏁈";
}

.fad.fa-step-forward:after {
  content: "􏁑";
}

.fad.fa-stethoscope:after {
  content: "􏃱";
}

.fad.fa-sticky-note:after {
  content: "􏉉";
}

.fad.fa-stocking:after {
  content: "􏟕";
}

.fad.fa-stomach:after {
  content: "􏘣";
}

.fad.fa-stop:after {
  content: "􏁍";
}

.fad.fa-stop-circle:after {
  content: "􏊍";
}

.fad.fa-stopwatch:after {
  content: "􏋲";
}

.fad.fa-stopwatch-20:after {
  content: "􎁯";
}

.fad.fa-store:after {
  content: "􏕎";
}

.fad.fa-store-alt:after {
  content: "􏕏";
}

.fad.fa-store-alt-slash:after {
  content: "􎁰";
}

.fad.fa-store-slash:after {
  content: "􎁱";
}

.fad.fa-stream:after {
  content: "􏕐";
}

.fad.fa-street-view:after {
  content: "􏈝";
}

.fad.fa-stretcher:after {
  content: "􏠥";
}

.fad.fa-strikethrough:after {
  content: "􏃌";
}

.fad.fa-stroopwafel:after {
  content: "􏕑";
}

.fad.fa-subscript:after {
  content: "􏄬";
}

.fad.fa-subway:after {
  content: "􏈹";
}

.fad.fa-suitcase:after {
  content: "􏃲";
}

.fad.fa-suitcase-rolling:after {
  content: "􏗁";
}

.fad.fa-sun:after {
  content: "􏆅";
}

.fad.fa-sun-cloud:after {
  content: "􏝣";
}

.fad.fa-sun-dust:after {
  content: "􏝤";
}

.fad.fa-sun-haze:after {
  content: "􏝥";
}

.fad.fa-sunglasses:after {
  content: "􏢒";
}

.fad.fa-sunrise:after {
  content: "􏝦";
}

.fad.fa-sunset:after {
  content: "􏝧";
}

.fad.fa-superscript:after {
  content: "􏄫";
}

.fad.fa-surprise:after {
  content: "􏗂";
}

.fad.fa-swatchbook:after {
  content: "􏗃";
}

.fad.fa-swimmer:after {
  content: "􏗄";
}

.fad.fa-swimming-pool:after {
  content: "􏗅";
}

.fad.fa-sword:after {
  content: "􏜜";
}

.fad.fa-sword-laser:after {
  content: "􎀻";
}

.fad.fa-sword-laser-alt:after {
  content: "􎀼";
}

.fad.fa-swords:after {
  content: "􏜝";
}

.fad.fa-swords-laser:after {
  content: "􎀽";
}

.fad.fa-synagogue:after {
  content: "􏚛";
}

.fad.fa-sync:after {
  content: "􏀡";
}

.fad.fa-sync-alt:after {
  content: "􏋱";
}

.fad.fa-syringe:after {
  content: "􏒎";
}

.fad.fa-table:after {
  content: "􏃎";
}

.fad.fa-table-tennis:after {
  content: "􏑝";
}

.fad.fa-tablet:after {
  content: "􏄊";
}

.fad.fa-tablet-alt:after {
  content: "􏏺";
}

.fad.fa-tablet-android:after {
  content: "􏏻";
}

.fad.fa-tablet-android-alt:after {
  content: "􏏼";
}

.fad.fa-tablet-rugged:after {
  content: "􏒏";
}

.fad.fa-tablets:after {
  content: "􏒐";
}

.fad.fa-tachometer:after {
  content: "􏃤";
}

.fad.fa-tachometer-alt:after {
  content: "􏏽";
}

.fad.fa-tachometer-alt-average:after {
  content: "􏘤";
}

.fad.fa-tachometer-alt-fast:after {
  content: "􏘥";
}

.fad.fa-tachometer-alt-fastest:after {
  content: "􏘦";
}

.fad.fa-tachometer-alt-slow:after {
  content: "􏘧";
}

.fad.fa-tachometer-alt-slowest:after {
  content: "􏘨";
}

.fad.fa-tachometer-average:after {
  content: "􏘩";
}

.fad.fa-tachometer-fast:after {
  content: "􏘪";
}

.fad.fa-tachometer-fastest:after {
  content: "􏘫";
}

.fad.fa-tachometer-slow:after {
  content: "􏘬";
}

.fad.fa-tachometer-slowest:after {
  content: "􏘭";
}

.fad.fa-taco:after {
  content: "􏠦";
}

.fad.fa-tag:after {
  content: "􏀫";
}

.fad.fa-tags:after {
  content: "􏀬";
}

.fad.fa-tally:after {
  content: "􏚜";
}

.fad.fa-tanakh:after {
  content: "􏠧";
}

.fad.fa-tape:after {
  content: "􏓛";
}

.fad.fa-tasks:after {
  content: "􏂮";
}

.fad.fa-tasks-alt:after {
  content: "􏠨";
}

.fad.fa-taxi:after {
  content: "􏆺";
}

.fad.fa-teeth:after {
  content: "􏘮";
}

.fad.fa-teeth-open:after {
  content: "􏘯";
}

.fad.fa-telescope:after {
  content: "􎀾";
}

.fad.fa-temperature-down:after {
  content: "􎀿";
}

.fad.fa-temperature-frigid:after {
  content: "􏝨";
}

.fad.fa-temperature-high:after {
  content: "􏝩";
}

.fad.fa-temperature-hot:after {
  content: "􏝪";
}

.fad.fa-temperature-low:after {
  content: "􏝫";
}

.fad.fa-temperature-up:after {
  content: "􎁀";
}

.fad.fa-tenge:after {
  content: "􏟗";
}

.fad.fa-tennis-ball:after {
  content: "􏑞";
}

.fad.fa-terminal:after {
  content: "􏄠";
}

.fad.fa-text:after {
  content: "􏢓";
}

.fad.fa-text-height:after {
  content: "􏀴";
}

.fad.fa-text-size:after {
  content: "􏢔";
}

.fad.fa-text-width:after {
  content: "􏀵";
}

.fad.fa-th:after {
  content: "􏀊";
}

.fad.fa-th-large:after {
  content: "􏀉";
}

.fad.fa-th-list:after {
  content: "􏀋";
}

.fad.fa-theater-masks:after {
  content: "􏘰";
}

.fad.fa-thermometer:after {
  content: "􏒑";
}

.fad.fa-thermometer-empty:after {
  content: "􏋋";
}

.fad.fa-thermometer-full:after {
  content: "􏋇";
}

.fad.fa-thermometer-half:after {
  content: "􏋉";
}

.fad.fa-thermometer-quarter:after {
  content: "􏋊";
}

.fad.fa-thermometer-three-quarters:after {
  content: "􏋈";
}

.fad.fa-theta:after {
  content: "􏚞";
}

.fad.fa-thumbs-down:after {
  content: "􏅥";
}

.fad.fa-thumbs-up:after {
  content: "􏅤";
}

.fad.fa-thumbtack:after {
  content: "􏂍";
}

.fad.fa-thunderstorm:after {
  content: "􏝬";
}

.fad.fa-thunderstorm-moon:after {
  content: "􏝭";
}

.fad.fa-thunderstorm-sun:after {
  content: "􏝮";
}

.fad.fa-ticket:after {
  content: "􏅅";
}

.fad.fa-ticket-alt:after {
  content: "􏏿";
}

.fad.fa-tilde:after {
  content: "􏚟";
}

.fad.fa-times:after {
  content: "􏀍";
}

.fad.fa-times-circle:after {
  content: "􏁗";
}

.fad.fa-times-hexagon:after {
  content: "􏋮";
}

.fad.fa-times-octagon:after {
  content: "􏋰";
}

.fad.fa-times-square:after {
  content: "􏋓";
}

.fad.fa-tint:after {
  content: "􏁃";
}

.fad.fa-tint-slash:after {
  content: "􏗇";
}

.fad.fa-tire:after {
  content: "􏘱";
}

.fad.fa-tire-flat:after {
  content: "􏘲";
}

.fad.fa-tire-pressure-warning:after {
  content: "􏘳";
}

.fad.fa-tire-rugged:after {
  content: "􏘴";
}

.fad.fa-tired:after {
  content: "􏗈";
}

.fad.fa-toggle-off:after {
  content: "􏈄";
}

.fad.fa-toggle-on:after {
  content: "􏈅";
}

.fad.fa-toilet:after {
  content: "􏟘";
}

.fad.fa-toilet-paper:after {
  content: "􏜞";
}

.fad.fa-toilet-paper-alt:after {
  content: "􏜟";
}

.fad.fa-toilet-paper-slash:after {
  content: "􎁲";
}

.fad.fa-tombstone:after {
  content: "􏜠";
}

.fad.fa-tombstone-alt:after {
  content: "􏜡";
}

.fad.fa-toolbox:after {
  content: "􏕒";
}

.fad.fa-tools:after {
  content: "􏟙";
}

.fad.fa-tooth:after {
  content: "􏗉";
}

.fad.fa-toothbrush:after {
  content: "􏘵";
}

.fad.fa-torah:after {
  content: "􏚠";
}

.fad.fa-torii-gate:after {
  content: "􏚡";
}

.fad.fa-tornado:after {
  content: "􏝯";
}

.fad.fa-tractor:after {
  content: "􏜢";
}

.fad.fa-trademark:after {
  content: "􏉜";
}

.fad.fa-traffic-cone:after {
  content: "􏘶";
}

.fad.fa-traffic-light:after {
  content: "􏘷";
}

.fad.fa-traffic-light-go:after {
  content: "􏘸";
}

.fad.fa-traffic-light-slow:after {
  content: "􏘹";
}

.fad.fa-traffic-light-stop:after {
  content: "􏘺";
}

.fad.fa-trailer:after {
  content: "􎁁";
}

.fad.fa-train:after {
  content: "􏈸";
}

.fad.fa-tram:after {
  content: "􏟚";
}

.fad.fa-transgender:after {
  content: "􏈤";
}

.fad.fa-transgender-alt:after {
  content: "􏈥";
}

.fad.fa-transporter:after {
  content: "􎁂";
}

.fad.fa-transporter-1:after {
  content: "􎁃";
}

.fad.fa-transporter-2:after {
  content: "􎁄";
}

.fad.fa-transporter-3:after {
  content: "􎁅";
}

.fad.fa-transporter-empty:after {
  content: "􎁆";
}

.fad.fa-trash:after {
  content: "􏇸";
}

.fad.fa-trash-alt:after {
  content: "􏋭";
}

.fad.fa-trash-restore:after {
  content: "􏠩";
}

.fad.fa-trash-restore-alt:after {
  content: "􏠪";
}

.fad.fa-trash-undo:after {
  content: "􏢕";
}

.fad.fa-trash-undo-alt:after {
  content: "􏢖";
}

.fad.fa-treasure-chest:after {
  content: "􏜣";
}

.fad.fa-tree:after {
  content: "􏆻";
}

.fad.fa-tree-alt:after {
  content: "􏐀";
}

.fad.fa-tree-christmas:after {
  content: "􏟛";
}

.fad.fa-tree-decorated:after {
  content: "􏟜";
}

.fad.fa-tree-large:after {
  content: "􏟝";
}

.fad.fa-tree-palm:after {
  content: "􏠫";
}

.fad.fa-trees:after {
  content: "􏜤";
}

.fad.fa-triangle:after {
  content: "􏋬";
}

.fad.fa-triangle-music:after {
  content: "􏣢";
}

.fad.fa-trophy:after {
  content: "􏂑";
}

.fad.fa-trophy-alt:after {
  content: "􏋫";
}

.fad.fa-truck:after {
  content: "􏃑";
}

.fad.fa-truck-container:after {
  content: "􏓜";
}

.fad.fa-truck-couch:after {
  content: "􏓝";
}

.fad.fa-truck-loading:after {
  content: "􏓞";
}

.fad.fa-truck-monster:after {
  content: "􏘻";
}

.fad.fa-truck-moving:after {
  content: "􏓟";
}

.fad.fa-truck-pickup:after {
  content: "􏘼";
}

.fad.fa-truck-plow:after {
  content: "􏟞";
}

.fad.fa-truck-ramp:after {
  content: "􏓠";
}

.fad.fa-trumpet:after {
  content: "􏣣";
}

.fad.fa-tshirt:after {
  content: "􏕓";
}

.fad.fa-tty:after {
  content: "􏇤";
}

.fad.fa-turkey:after {
  content: "􏜥";
}

.fad.fa-turntable:after {
  content: "􏣤";
}

.fad.fa-turtle:after {
  content: "􏜦";
}

.fad.fa-tv:after {
  content: "􏉬";
}

.fad.fa-tv-alt:after {
  content: "􏣥";
}

.fad.fa-tv-music:after {
  content: "􏣦";
}

.fad.fa-tv-retro:after {
  content: "􏐁";
}

.fad.fa-typewriter:after {
  content: "􏣧";
}

.fad.fa-ufo:after {
  content: "􎁇";
}

.fad.fa-ufo-beam:after {
  content: "􎁈";
}

.fad.fa-umbrella:after {
  content: "􏃩";
}

.fad.fa-umbrella-beach:after {
  content: "􏗊";
}

.fad.fa-underline:after {
  content: "􏃍";
}

.fad.fa-undo:after {
  content: "􏃢";
}

.fad.fa-undo-alt:after {
  content: "􏋪";
}

.fad.fa-unicorn:after {
  content: "􏜧";
}

.fad.fa-union:after {
  content: "􏚢";
}

.fad.fa-universal-access:after {
  content: "􏊚";
}

.fad.fa-university:after {
  content: "􏆜";
}

.fad.fa-unlink:after {
  content: "􏄧";
}

.fad.fa-unlock:after {
  content: "􏂜";
}

.fad.fa-unlock-alt:after {
  content: "􏄾";
}

.fad.fa-upload:after {
  content: "􏂓";
}

.fad.fa-usb-drive:after {
  content: "􏣩";
}

.fad.fa-usd-circle:after {
  content: "􏋨";
}

.fad.fa-usd-square:after {
  content: "􏋩";
}

.fad.fa-user:after {
  content: "􏀇";
}

.fad.fa-user-alien:after {
  content: "􎁊";
}

.fad.fa-user-alt:after {
  content: "􏐆";
}

.fad.fa-user-alt-slash:after {
  content: "􏓺";
}

.fad.fa-user-astronaut:after {
  content: "􏓻";
}

.fad.fa-user-chart:after {
  content: "􏚣";
}

.fad.fa-user-check:after {
  content: "􏓼";
}

.fad.fa-user-circle:after {
  content: "􏊽";
}

.fad.fa-user-clock:after {
  content: "􏓽";
}

.fad.fa-user-cog:after {
  content: "􏓾";
}

.fad.fa-user-cowboy:after {
  content: "􏣪";
}

.fad.fa-user-crown:after {
  content: "􏚤";
}

.fad.fa-user-edit:after {
  content: "􏓿";
}

.fad.fa-user-friends:after {
  content: "􏔀";
}

.fad.fa-user-graduate:after {
  content: "􏔁";
}

.fad.fa-user-hard-hat:after {
  content: "􏠬";
}

.fad.fa-user-headset:after {
  content: "􏠭";
}

.fad.fa-user-injured:after {
  content: "􏜨";
}

.fad.fa-user-lock:after {
  content: "􏔂";
}

.fad.fa-user-md:after {
  content: "􏃰";
}

.fad.fa-user-md-chat:after {
  content: "􏠮";
}

.fad.fa-user-minus:after {
  content: "􏔃";
}

.fad.fa-user-music:after {
  content: "􏣫";
}

.fad.fa-user-ninja:after {
  content: "􏔄";
}

.fad.fa-user-nurse:after {
  content: "􏠯";
}

.fad.fa-user-plus:after {
  content: "􏈴";
}

.fad.fa-user-robot:after {
  content: "􎁋";
}

.fad.fa-user-secret:after {
  content: "􏈛";
}

.fad.fa-user-shield:after {
  content: "􏔅";
}

.fad.fa-user-slash:after {
  content: "􏔆";
}

.fad.fa-user-tag:after {
  content: "􏔇";
}

.fad.fa-user-tie:after {
  content: "􏔈";
}

.fad.fa-user-times:after {
  content: "􏈵";
}

.fad.fa-user-unlock:after {
  content: "􎁘";
}

.fad.fa-user-visor:after {
  content: "􎁌";
}

.fad.fa-users:after {
  content: "􏃀";
}

.fad.fa-users-class:after {
  content: "􏘽";
}

.fad.fa-users-cog:after {
  content: "􏔉";
}

.fad.fa-users-crown:after {
  content: "􏚥";
}

.fad.fa-users-medical:after {
  content: "􏠰";
}

.fad.fa-users-slash:after {
  content: "􎁳";
}

.fad.fa-utensil-fork:after {
  content: "􏋣";
}

.fad.fa-utensil-knife:after {
  content: "􏋤";
}

.fad.fa-utensil-spoon:after {
  content: "􏋥";
}

.fad.fa-utensils:after {
  content: "􏋧";
}

.fad.fa-utensils-alt:after {
  content: "􏋦";
}

.fad.fa-vacuum:after {
  content: "􎁍";
}

.fad.fa-vacuum-robot:after {
  content: "􎁎";
}

.fad.fa-value-absolute:after {
  content: "􏚦";
}

.fad.fa-vector-square:after {
  content: "􏗋";
}

.fad.fa-venus:after {
  content: "􏈡";
}

.fad.fa-venus-double:after {
  content: "􏈦";
}

.fad.fa-venus-mars:after {
  content: "􏈨";
}

.fad.fa-vest:after {
  content: "􎂅";
}

.fad.fa-vest-patches:after {
  content: "􎂆";
}

.fad.fa-vhs:after {
  content: "􏣬";
}

.fad.fa-vial:after {
  content: "􏒒";
}

.fad.fa-vials:after {
  content: "􏒓";
}

.fad.fa-video:after {
  content: "􏀽";
}

.fad.fa-video-plus:after {
  content: "􏓡";
}

.fad.fa-video-slash:after {
  content: "􏓢";
}

.fad.fa-vihara:after {
  content: "􏚧";
}

.fad.fa-violin:after {
  content: "􏣭";
}

.fad.fa-virus:after {
  content: "􎁴";
}

.fad.fa-virus-slash:after {
  content: "􎁵";
}

.fad.fa-viruses:after {
  content: "􎁶";
}

.fad.fa-voicemail:after {
  content: "􏢗";
}

.fad.fa-volcano:after {
  content: "􏝰";
}

.fad.fa-volleyball-ball:after {
  content: "􏑟";
}

.fad.fa-volume:after {
  content: "􏚨";
}

.fad.fa-volume-down:after {
  content: "􏀧";
}

.fad.fa-volume-mute:after {
  content: "􏚩";
}

.fad.fa-volume-off:after {
  content: "􏀦";
}

.fad.fa-volume-slash:after {
  content: "􏋢";
}

.fad.fa-volume-up:after {
  content: "􏀨";
}

.fad.fa-vote-nay:after {
  content: "􏝱";
}

.fad.fa-vote-yea:after {
  content: "􏝲";
}

.fad.fa-vr-cardboard:after {
  content: "􏜩";
}

.fad.fa-wagon-covered:after {
  content: "􏣮";
}

.fad.fa-walker:after {
  content: "􏠱";
}

.fad.fa-walkie-talkie:after {
  content: "􏣯";
}

.fad.fa-walking:after {
  content: "􏕔";
}

.fad.fa-wallet:after {
  content: "􏕕";
}

.fad.fa-wand:after {
  content: "􏜪";
}

.fad.fa-wand-magic:after {
  content: "􏜫";
}

.fad.fa-warehouse:after {
  content: "􏒔";
}

.fad.fa-warehouse-alt:after {
  content: "􏒕";
}

.fad.fa-washer:after {
  content: "􏢘";
}

.fad.fa-watch:after {
  content: "􏋡";
}

.fad.fa-watch-calculator:after {
  content: "􏣰";
}

.fad.fa-watch-fitness:after {
  content: "􏘾";
}

.fad.fa-water:after {
  content: "􏝳";
}

.fad.fa-water-lower:after {
  content: "􏝴";
}

.fad.fa-water-rise:after {
  content: "􏝵";
}

.fad.fa-wave-sine:after {
  content: "􏢙";
}

.fad.fa-wave-square:after {
  content: "􏠾";
}

.fad.fa-wave-triangle:after {
  content: "􏢚";
}

.fad.fa-waveform:after {
  content: "􏣱";
}

.fad.fa-waveform-path:after {
  content: "􏣲";
}

.fad.fa-webcam:after {
  content: "􏠲";
}

.fad.fa-webcam-slash:after {
  content: "􏠳";
}

.fad.fa-weight:after {
  content: "􏒖";
}

.fad.fa-weight-hanging:after {
  content: "􏗍";
}

.fad.fa-whale:after {
  content: "􏜬";
}

.fad.fa-wheat:after {
  content: "􏜭";
}

.fad.fa-wheelchair:after {
  content: "􏆓";
}

.fad.fa-whistle:after {
  content: "􏑠";
}

.fad.fa-wifi:after {
  content: "􏇫";
}

.fad.fa-wifi-1:after {
  content: "􏚪";
}

.fad.fa-wifi-2:after {
  content: "􏚫";
}

.fad.fa-wifi-slash:after {
  content: "􏚬";
}

.fad.fa-wind:after {
  content: "􏜮";
}

.fad.fa-wind-turbine:after {
  content: "􏢛";
}

.fad.fa-wind-warning:after {
  content: "􏝶";
}

.fad.fa-window:after {
  content: "􏐎";
}

.fad.fa-window-alt:after {
  content: "􏐏";
}

.fad.fa-window-close:after {
  content: "􏐐";
}

.fad.fa-window-frame:after {
  content: "􎁏";
}

.fad.fa-window-frame-open:after {
  content: "􎁐";
}

.fad.fa-window-maximize:after {
  content: "􏋐";
}

.fad.fa-window-minimize:after {
  content: "􏋑";
}

.fad.fa-window-restore:after {
  content: "􏋒";
}

.fad.fa-windsock:after {
  content: "􏝷";
}

.fad.fa-wine-bottle:after {
  content: "􏜯";
}

.fad.fa-wine-glass:after {
  content: "􏓣";
}

.fad.fa-wine-glass-alt:after {
  content: "􏗎";
}

.fad.fa-won-sign:after {
  content: "􏅙";
}

.fad.fa-wreath:after {
  content: "􏟢";
}

.fad.fa-wrench:after {
  content: "􏂭";
}

.fad.fa-x-ray:after {
  content: "􏒗";
}

.fad.fa-yen-sign:after {
  content: "􏅗";
}

.fad.fa-yin-yang:after {
  content: "􏚭";
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.e9cc77a3.eot");
  src: url("fa-light-300.e9cc77a3.eot#iefix") format("embedded-opentype"), url("fa-light-300.ece18d09.woff2") format("woff2"), url("fa-light-300.7a5349e6.woff") format("woff"), url("fa-light-300.d54c126a.ttf") format("truetype"), url("fa-light-300.ca82ec62.svg#fontawesome") format("svg");
}

.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.e5f80229.eot");
  src: url("fa-regular-400.e5f80229.eot#iefix") format("embedded-opentype"), url("fa-regular-400.609f9c4c.woff2") format("woff2"), url("fa-regular-400.6b0adee8.woff") format("woff"), url("fa-regular-400.02fc59cb.ttf") format("truetype"), url("fa-regular-400.62c97122.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.8c589fd1.eot");
  src: url("fa-solid-900.8c589fd1.eot#iefix") format("embedded-opentype"), url("fa-solid-900.f2049a98.woff2") format("woff2"), url("fa-solid-900.c7b072c6.woff") format("woff"), url("fa-solid-900.515704be.ttf") format("truetype"), url("fa-solid-900.7ba04835.svg#fontawesome") format("svg");
}

.fa, .fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

/*# sourceMappingURL=index.3cb3483a.css.map */
